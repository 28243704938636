import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
  loginFailedAction,
} from "../../store/actions/AuthActions";

//
// import logo from '../../images/logo.png'
// import textlogo from '../../images/logo-text.png'

import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";
import loginlogo from "../../images/stockvellogo.png";
import axios from "axios";
import { formatError } from "../../services/AuthService";
import { Modal } from "react-bootstrap";
import axiosInstance from "../../services/AxiosInstance";
import { toast, ToastContainer } from "react-toastify";
import swal from "@sweetalert/with-react";
import { Box, Grid } from "@material-ui/core";

function Login(props) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [showforgotmodal, setShowForgotModal] = useState(false);
  const [forgotemail, setForgotEmail] = useState("");
  const [showforgototpverify, setShowForgotOTPverify] = useState("");
  const [forgotmailotpvalue, setForgotMailOTPvalue] = useState("");
  const [showforgotpwdreset, setShowForgotPWDreset] = useState("");
  const [forgotpwdvalue, setForgotPWDValue] = useState("");
  const [forgotemailerror, setForgotEmailError] = useState(false);
  const [forgotpassworderror, setForgotPasswordError] = useState(false);

  const handleClose = () => {
    setShowForgotModal(false);
    setForgotEmailError(false);
  };
  const handleShow = () => setShowForgotModal(true);

  const dispatch = useDispatch();

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (email === "") {
      errorObj.email = "Please Enter Your Email Id";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Please Enter Your Password";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    /* postLogin().then((res) => {
      if (res === false) {
        setErrors({ ...errorObj, email: "Invalid email or password." });
      } else {
        history.push('/dashboard');
      }
    }); */
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, props.history));
  }

  /*   async function postLogin() {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/login`,
        {
          email: email,
          password: password,
        }
      );
      if (res?.data?.success === true) return res?.data?.data;
      else return false;
    } catch (e) {
      return false;
    }
  }
 */

  const forgotpasswordhandle = () => {
    setShowForgotModal(true);
  };

  const forgotSendOTPhandle = (e) => {
    if (forgotemail === "") {
      setForgotEmailError(true);
    } else {
      localStorage.setItem("resetemail", forgotemail);

      let emaildata = {
        email: forgotemail,
      };

      axiosInstance
        .post(`/auth/forgotPassword`, emaildata)
        .then((res) => {
          console.log(res);
          if (
            res.data.data.message ===
            "User OTP sended Successfully! Plz Check your Mail!"
          ) {
            setShowForgotModal(false);
            toast.success("OTP Sent To Email, Please Check !", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setTimeout(() => {
              setShowForgotOTPverify(true);
            }, 1000);

            console.log("otp sended success");
          }
        })
        .catch(function (error) {
          console.log(error.response.data.errorMessage);

          toast.error("Please enter registered email-id", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  const forgototpverifyhandle = () => {
    let otpdata = {
      otp: forgotmailotpvalue,
    };

    axiosInstance
      .post(`/auth/verifyOtp`, otpdata)
      .then((res) => {
        console.log(res);

        if (res.data.data.message === "User OTP Verify Successfully!") {
          setShowForgotOTPverify(false);
          toast.success("OTP Verified Successfully !", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            setShowForgotPWDreset(true);
          }, 2000);

          console.log("otp successfull");
        }
      })
      .catch((error) => {
        console.log(error.response.data.errorMessage);

        if (error.response.data.errorMessage === "Wrong User OTP!") {
          toast.error("Please Enter Correct OTP !", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        /*     if (res.data.data.message === "Wrong User OTP!") {
        console.log("Wrong User OTP");

        toast.warning("Please Enter Correct OTP !", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } */
      });
  };

  const forgotpwdresethandle = () => {
    if (forgotpwdvalue === "") {
      setForgotPasswordError(true);
    } else {
      setForgotPasswordError(false);

      let passworddata = {
        email_id: localStorage.getItem("resetemail"),
        password: forgotpwdvalue,
      };

      axiosInstance.put(`/auth/resetPassword`, passworddata).then((res) => {
        console.log(res);
        if (res.data.data.message === "User Password Reset Successfully!") {
          setShowForgotPWDreset(false);
          swal({
            title: "Password Changed !",
            text: " Password Changed Successfully !",
            icon: "success",
            timer: 2000,
          });

          localStorage.removeItem("resetemail");
        }
      });
    }

    console.log("password resett request send", password);
  };

  const emptyclosehandle = () => { };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <>
        <Modal show={showforgotmodal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Forgot Password</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="">Email ID</label>

              <input
                type="text"
                className="form-control"
                placeholder="Please Enter Email ID"
                onChange={(e) => setForgotEmail(e.target.value)}
              />
            </div>
            {forgotemailerror && (
              <div>
                <span style={{ color: "orange" }}>Please Enter Email Id</span>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={handleClose}>
              Close
            </button>
            <button
              className="btn  "
              style={{ background: "yellowgreen", color: "white" }}
              onClick={forgotSendOTPhandle}
            >
              Send OTP
            </button>
          </Modal.Footer>
        </Modal>
      </>

      <>
        <Modal
          show={showforgototpverify}
          onHide={() => setShowForgotOTPverify(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Verify OTP</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="">Enter OTP</label>

              <input
                type="text"
                className="form-control"
                placeholder="Please Enter OTP"
                onChange={(e) => setForgotMailOTPvalue(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={() => setShowForgotOTPverify(false)}
            >
              Close
            </button>
            <button
              className="btn  "
              style={{ background: "yellowgreen", color: "white" }}
              onClick={forgototpverifyhandle}
            >
              Verify OTP
            </button>
          </Modal.Footer>
        </Modal>
      </>

      <>
        <Modal show={showforgotpwdreset} onHide={emptyclosehandle}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Set Password</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="">Set Password</label>

              <input
                type="password"
                className="form-control"
                placeholder="Set Your New Password"
                onChange={(e) => setForgotPWDValue(e.target.value)}
              />
            </div>
            <div>
              {forgotpassworderror && (
                <div>
                  {" "}
                  <span style={{ color: "orange" }}>
                    Please Set Your New Password
                  </span>{" "}
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={() => setShowForgotPWDreset(false)}
            >
              Close
            </button>
            <button
              className="btn  "
              style={{ background: "yellowgreen", color: "white" }}
              onClick={forgotpwdresethandle}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      </>
      <div
        className="login-main-page"
        style={{ backgroundImage: "url(" + loginbg + ")", height: "100vh" }}
      >
        <div className="login-wrapper" style={{ borderRadius: "40px" }}>
          <div
            className="login-aside-left  "
            style={{ backgroundImage: "url(" + login + ")" }}
          >
            <div className="col-md-8 col-sm-8 col-lg-8">
              <img
                src={loginlogo}
                style={{ width: "100%", height: "auto" }}
                alt=""
              />

              {/*  <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg" class="img-fluid" alt='' /> */}
            </div>
            {/* <div className="login-description">
            <h2 className="text-black  mb-2">Check the Status</h2> 
            <p className="fs-12 text-dark">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout
            . The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,</p>
            <ul className="social-icons mt-4">
              <li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
              <li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
              <li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
            </ul>
            <div className="mt-5">
              <Link to={"#"} className="text-black mr-4">Privacy Policy</Link>
              <Link to={"#"} className="text-black mr-4">Contact</Link>
              <Link to={"#"} className="text-black">© 2021 DexignZone</Link>
            </div>
          </div> */}
          </div>
          <div className="login-aside-right">
            <div className="row m-0 justify-content-center h-100 align-items-center">
              <div className="col-xl-10 col-xxl-10">
                <div className="authincation-content">
                  <div className="row no-gutters">
                    <div className="col-xl-12">
                      <div className="auth-form-1">
                        <div className="mb-4">
                          <h3 className="text-primary mb-1">
                            Welcome to Stockvel Account
                          </h3>
                          <p className="">
                            Sign in by entering information below
                          </p>
                        </div>
                        {props.errorMessage && (
                          <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                            {props.errorMessage}
                          </div>
                        )}
                        {props.successMessage && (
                          <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                            {props.successMessage}
                          </div>
                        )}
                        <form onSubmit={onLogin}>
                          <div className="form-group mb-3">
                            <label className="text-label">Username *</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fa fa-user" />{" "}
                                </span>
                              </div>
                              <input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>

                            {errors.email && (
                              <div className="text-orange mt-2">
                                {errors.email}
                              </div>
                            )}

                            <div className="form-group mt-2 mb-3">
                              {/* <label className="mb-2 "><strong>Password</strong></label> */}
                              <label className="text-label">Password *</label>
                              <div className="input-group transparent-append">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    {" "}
                                    <i className="fa fa-lock" />{" "}
                                  </span>
                                </div>
                                <input
                                  type={`${showPassword ? "text" : "password"}`}
                                  className="form-control"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                <div
                                  className="input-group-append"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <span className="input-group-text">
                                      {" "}
                                      <i
                                        className="fa fa-eye"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </span>
                                  ) : (
                                    <span className="input-group-text">
                                      {" "}
                                      <i
                                        className="fa fa-eye-slash"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                              {errors.password && (
                                <div className="text-orange mt-2">
                                  {errors.password}
                                </div>
                              )}
                            </div>
                          </div>
                          {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">

                          <div className="form-group">
                            <div className="custom-control custom-checkbox ml-1 ">
                              <input
                                type="checkbox"
                                className="custom-control-input mr-2"
                                id="basic_checkbox_1"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="basic_checkbox_1"
                              >
                                Remember my preference
                              </label>
                            </div>
                          </div>
                        </div> */}
                          <Grid item md={12} sm={12} xs={12} >
                            <Box
                              className="text-primary text-right"
                              onClick={forgotpasswordhandle}
                              style={{ cursor: "pointer" }}
                            >
                              {/*    <Link className="text-primary" to="./page-register"> */}
                              Forgot Password?
                              {/*      </Link> */}
                            </Box>
                          </Grid>

                          <div className="text-center my-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Sign In
                            </button>
                          </div>

                          <Grid item md={12} sm={12} xs={12}>
                            <Box className=" text-center">
                              Don't have an account?{" "}

                              <Link
                                className="text-primary"
                                to="./page-register"
                              >
                                Sign up
                              </Link>
                            </Box>
                          </Grid>{" "}

                        </form>

                        {/*            <div className="login-description">
                          <ul className="social-icons m-2">
                            <li>
                              <Link to={"#"}>
                                <i className="fa fa-facebook"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to={"#"}>
                                <i className="fa fa-twitter"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to={"#"}>
                                <i className="fa fa-linkedin"></i>
                              </Link>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
