import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import table1 from "../../../../../images/table/Untitled-1.jpg";
import axiosInstance from "../../../../../services/AxiosInstance";
import sampledatalist from "./sampledata.json";

import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";

import "./styles.css";
import TablePagination from "@mui/material/TablePagination";

import groupavatar from "../../../../../images/avatar/groupavatar2.png";
function AdminGroupsList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const history = useHistory();
  const sampledata = sampledatalist;
  const [data, setData] = useState(
    document.querySelectorAll("#transaction_table tbody tr")
  );
  //const [activeName, setActiveName] = useState("Newest");
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  const [adminGroupData, setAdminGroupData] = useState([]);
  const [searchResult, setSearchResult] = useState({});
  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#transaction_table tbody tr"));
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  useEffect(() => {
    getGroups().then((res) => {
      console.log(res);
      getGroupMemberCount(res).then((res) => {
        console.log(res);
        setAdminGroupData(res);
      });
    });
  }, []);

  async function getGroups() {
    const res = await axiosInstance(
      `/grp/getGroupCreated/${
        JSON.parse(localStorage.getItem("userDetails")).userId
      }`
    );
    return res?.data?.data?.data;
  }

  async function getGroupMemberCount(data) {
    const temp = data?.map(async (row) => {
      const res = await axiosInstance.get(
        `/grp_member/getTotalGrpMember/${row?.id}`
      );
      row.memberCount = res?.data?.data?.count;
      return row;
    });
    return Promise.all(temp);
  }

  // pagination code
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - adminGroupData.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 3.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          style={{
            color: "white",
            borderRadius: "10px",
            background: page === 0 ? "#5b5288" : "#eb8153",
            marginLeft: "8px",
          }}
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background: page === 0 ? "#5b5288" : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background:
              page >= Math.ceil(count / rowsPerPage) - 1
                ? "#5b5288"
                : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background:
              page >= Math.ceil(count / rowsPerPage) - 1
                ? "#5b5288"
                : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const lowersearch = searchResult?.result?.toString().toLowerCase().trim();

  const usersearchall = adminGroupData
    ?.filter((sv) => sv.group_name.toLowerCase().includes(lowersearch))
    .map((sitem) => {
      return sitem;
    });

  console.log(usersearchall);

  const onSearchHandler = (e) => {
    console.log(e.target.value, e.target.value.length);
    setSearchResult({
      result: e.target.value,
      length: e.target.value.length,
    });
  };

  return (
    <div>
      <div className="">
        <div className="d-flex flex-wrap " style={{ alignItems: "baseline" }}>
          <div className="col float-left my-3 text-nowrap">
            <span style={{ fontSize: "19px", color: "maroon" }}>
              You Are a Admin of these Groups
            </span>
            <br />
          </div>

          <div className="col float-right my-3">
            <input
              className="form-control "
              type="text"
              name=""
              onChange={onSearchHandler}
              placeholder="Search Group"
              id="searchInput"
            />
          </div>
        </div>
        <div className="table-responsive table-hover">
          <div id="example5_wrapper" className="dataTables_wrapper no-footer">
            <table
              className="table display mb-4 dataTablesCard short-one card-table text-black dataTable no-footer"
              id="transaction_table"
              role="grid"
              aria-describedby="example5_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Transaction ID: activate to sort column ascending"
                    style={{ width: 124 }}
                  >
                    {" "}
                    S.No
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Group Name
                  </th>
                  {/*      <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="From: activate to sort column ascending"
                    style={{ width: 62 }}
                  >
                    Created On
                  </th> */}

                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="From: activate to sort column ascending"
                    style={{ width: 62 }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {adminGroupData.length === 0 && (
                  <tr>
                    <td colSpan={4}>No Records Found...</td>
                  </tr>
                )} */}

                {adminGroupData.length === 0 ? (
                  <tr>
                    <td colSpan={2}>
                      <span style={{ color: "orange", fontSize: "22px" }}>
                        You have not created any groups
                      </span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {searchResult.length > 0 ? (
                  <>
                    {usersearchall.length === 0 ? (
                      <>
                        <tr>
                          <td colSpan={4} style={{ textAlign: "center" }}>
                            <span style={{ color: "orange", fontSize: "22px" }}>
                              No Records Found
                            </span>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>{""}</>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {searchResult.length > 0 ? (
                  <>
                    {usersearchall?.map((uitem, i) => (
                      <React.Fragment>
                        {usersearchall?.length > 0 ? (
                          <>
                            <tr role="row" className="odd">
                              <td>#{i + 1}</td>
                              <td>
                                <div className="d-flex align-items-center justify-content-start">
                                  <div>
                                    <img
                                      src={groupavatar}
                                      alt=""
                                      className="rounded-circle mr-2 width40 height40"
                                    />
                                  </div>

                                  <div className="d-flex flex-column">
                                    <span>{uitem.group_name}</span>
                                    <div style={{ display: "flex" }}>
                                      <span>{uitem.memberCount} </span>
                                      <span style={{ marginLeft: "5px" }}>
                                        {uitem?.memberCount === 1
                                          ? "member"
                                          : "members"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              {/*       <td>
                                <div className="d-flex align-items-center">
                                  <span>
                                    {new moment(uitem.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </div>
                              </td> */}
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <Link to="/stockvelgroups/groupname/managegroup">
                                      <button className="btn btn-primary btn-sm">
                                        Manage
                                      </button>
                                    </Link> */}
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={(e) => {
                                      history.push({
                                        pathname: `/stockvelgroups/managegroup/${uitem.id}`,
                                        state: uitem?.id,
                                      });
                                    }}
                                  >
                                    Manage
                                  </button>
                                  {/*             <button className="btn btn-warning btn-sm mx-2">
                                    Reports
                                  </button> */}
                                </div>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    {(rowsPerPage > 0
                      ? adminGroupData.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : adminGroupData
                    ).map((admingroup, index) => (
                      <tr role="row" className="odd">
                        <td>{index + 1 + 1 * page * rowsPerPage}</td>
                        <td>
                          <div className="d-flex align-items-center justify-content-start">
                            <div>
                              <img
                                src={groupavatar}
                                alt=""
                                className="rounded-circle mr-2 width40 height40"
                              />
                            </div>

                            <div className="d-flex flex-column">
                              <span>{admingroup.group_name}</span>
                              <div style={{ display: "flex" }}>
                                <span style={{ paddingRight: "5px" }}>
                                  {admingroup.memberCount}{" "}
                                </span>
                                <span>
                                  {admingroup?.memberCount === 1
                                    ? "member"
                                    : "members"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        {/*       <td>
                          <div className="d-flex align-items-center">
                            <span>
                              {new moment(admingroup.createdAt).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                          </div>
                        </td> */}
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            {/* <Link to="/stockvelgroups/groupname/managegroup">
                                      <button className="btn btn-primary btn-sm">
                                        Manage
                                      </button>
                                    </Link> */}
                            <button
                              className="btn text-white btn-sm"
                              style={{
                                background: "darkturquoise",
                                border: "none",
                              }}
                              onClick={(e) => {
                                history.push({
                                  pathname: `/stockvelgroups/managegroup/${admingroup.id}`,
                                  state: admingroup?.id,
                                });
                              }}
                            >
                              Manage
                            </button>
                            {/*       <button className="btn btn-warning btn-sm mx-2">
                              Reports
                            </button> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}

                {emptyRows > 0 && (
                  <tr style={{ height: 41 * emptyRows }}>
                    <td colSpan={4} />
                  </tr>
                )}
              </tbody>
              <tfoot>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={adminGroupData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelRowsPerPage="Rows"
                />
              </tfoot>

              {/*     {adminGroupData.map((admingroup, index) => (
                  <tr role="row" className="odd">
                    <td>#{index + 1}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-start">
                        <div>
                          <img
                            src={"/static/media/Untitled-3.483d0297.jpg"}
                            alt=""
                            className="rounded-circle mr-2 width40 height40"
                          />
                        </div>

                        <div className="d-flex flex-column">
                          <span>{admingroup.group_name}</span>
                          <span>{admingroup.memberCount} Members</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span>
                          {new moment(admingroup.createdAt).format(
                            "DD/MM/YYYY"
                          )}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div> */}
              {/* <Link to="/stockvelgroups/groupname/managegroup">
                          <button className="btn btn-primary btn-sm">
                            Manage
                          </button>
                        </Link> */}
              {/*           <button
                          className="btn btn-primary btn-sm"
                          onClick={(e) => {
                            history.push({
                              pathname: `/stockvelgroups/managegroup/${admingroup.id}`,
                              state: admingroup?.id,
                            });
                          }}
                        >
                          Manage
                        </button>
                        <button className="btn btn-warning btn-sm mx-2">
                          Reports
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody> */}
            </table>

            {/*        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
              <div className="dataTables_info">
                Showing {activePag.current * sort + 1} to{" "}
                {adminGroupData.length > (activePag.current + 1) * sort
                  ? (activePag.current + 1) * sort
                  : adminGroupData.length}{" "}
                of {adminGroupData.length} entries
              </div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="example5_paginate"
              >
                <Link
                  className="paginate_button previous disabled"
                  to="/stockvelgroups"
                  onClick={() =>
                    activePag.current > 0 && onClick(activePag.current - 1)
                  }
                >
                  Previous
                </Link>
                <span>
                  {paggination.map((number, i) => (
                    <Link
                      key={i}
                      to="/stockvelgroups"
                      className={`paginate_button  ${
                        activePag.current === i ? "current" : ""
                      } `}
                      onClick={() => onClick(i)}
                      style={{ display: "inline-block" }}
                    >
                      {number}
                    </Link>
                  ))}
                </span>
                <Link
                  className="paginate_button next"
                  to="/stockvelgroups"
                  onClick={() =>
                    activePag.current + 1 < paggination.length &&
                    onClick(activePag.current + 1)
                  }
                >
                  Next
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminGroupsList;
