import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Button,
  ProgressBar,
} from "react-bootstrap";
import swal from "sweetalert";
import axiosInstance from "../../../../../../services/AxiosInstance";
import Header from "../../../../../layouts/nav/Header";
import { ProfileData } from "../../jsonData/profiledata";

function Password() {
  const [oldpassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const userid = JSON.parse(localStorage.getItem("userDetails")).userId;
  console.log(oldpassword, password, password2);
  let errorsObj = { oldpassword: "", password: "", password2: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [err, setErr] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showModal,setShow] = useState(true);
  const validateForm = (e) => {
    e.preventDefault();
    let valid = false;
    let error = false;
    const errorObj = { ...errorsObj };

    if (oldpassword === "") {
      errorObj.oldpassword = "Please enter your current password";
      error = true;
      valid = true;

    }
    if (password === "") {
      errorObj.password = "Please enter your password";
      error = true;
      valid = true;
    }
    if (password2 !== password) {
      errorObj.password2 = "Password must be matched";
      error = true;
      valid = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    

    if (valid) {
      console.log("unable to submit", valid)

    }
    else {
      updatePassword();

      console.log("form submitted", valid)
    }
  }
  const updatePassword = (e) => {

    const passworddata = {
      oldPassword: oldpassword,
      newPassword: password,
    };

    console.log(passworddata);
    axiosInstance
      .post(`/user/changePassword/${userid}`, passworddata)
      .then((res) => {
        console.log(res?.data?.data?.message);
        if (!res?.response?.data?.success) {
          swal({
            title: "Password Updated!",
            text: "Password Changed Successfully",
            icon: "success",
            timer: 2000,
          }).then(() => {
            setPassword("");
            setPassword2("");
            setOldPassword("");
          });
        }
        else {
          console.log(res?.response?.data)
          setErr(false)
        }

      }).catch(function (error) {
        console.log(error?.response?.data?.success)
        // console.log(error.response.data)
        if(!error?.response?.data?.success){
          setErr(true)
          swal({
            title: "Current Password is Incorrect!",
            text: "please enter correct password",
            icon: "error",
            timer: 2000,
          })
        }
        else{
          setErr(false)
        }
          
      })







  };




  return (
    <div>


      <div className="form-group d-flex justify-content-center">

        <form onSubmit={validateForm}>
          <div className="form-group mb-3">
            <label className="text-label">Current Password *</label>
            <div className="input-group">

              <input
                type={`${showCurrentPassword ? "text" : "password"}`}
                id="cpass"
                className="form-control"
                value={oldpassword}
                onChange={(e) => setOldPassword(e.target.value)}
                size="40"
              />
              <div
                className="input-group-append show-pass"
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              >
                <span className="input-group-text">
                  {" "}
                  <i className="fa fa-eye-slash" />
                  
                </span>
              </div>
            </div>

            {errors.oldpassword && (
              <div className="text-orange mt-2">
                {errors.oldpassword}
              </div>
            )}
            {err ? (
              <div className="text-orange mt-2">
                wrong password
              </div>
            ) : ""}


            <div className="form-group mt-2 mb-3">
              {/* <label className="mb-2 "><strong>Password</strong></label> */}
              <label className="text-label">Password *</label>
              <div className="input-group transparent-append">
                <input
                  type={`${showNewPassword ? "text" : "password"}`}
                  id="npass"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div
                  className="input-group-append show-pass"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  <span className="input-group-text">
                    {" "}
                    <i className="fa fa-eye-slash" />
                  </span>
                </div>
              </div>
              {errors.password && (
                <div className="text-orange mt-2">
                  {errors.password}
                </div>
              )}
            </div>
            <div className="form-group mt-2 mb-3">
              {/* <label className="mb-2 "><strong>Password</strong></label> */}
              <label className="text-label">Confirm Password *</label>
              <div className="input-group transparent-append">

                <input
                  type={`${showConfirmPassword ? "text" : "password"}`}
                  id="conpass"
                  className="form-control"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                />
                <div
                  className="input-group-append show-pass"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  <span className="input-group-text">
                    {" "}
                    <i className="fa fa-eye-slash" />
                  </span>
                </div>
              </div>
              {errors.password2 && (
                <div className="text-orange mt-2">
                  {errors.password2}
                </div>
              )}
            </div>
          </div>


          <div className="">
            <button
              type="submit"
              className="btn btn-primary btn-block"
            >
              Update
            </button>
          </div>
        </form>
      </div>
              
    </div>
  );
}

export default Password;
