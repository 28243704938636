import React, { useState, useRef, useEffect } from "react";
import paymentloanrepaymentdata from "./paymentsloanrepaymentdata.json";
import { Link } from "react-router-dom";
function Payments_LoanRepaymentsTab() {
  const loanrepaymentdata = paymentloanrepaymentdata;

  const [data, setData] = useState(
    document.querySelectorAll("#transaction_table tbody tr")
  );
  //const [activeName, setActiveName] = useState("Newest");
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#transaction_table tbody tr"));
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  return (
    <div>
      <div className="col">
        <div className="table-responsive table-hover fs-14">
          <div className="d-flex row">
            <div className="col float-left mx-4 my-3">
              <p>Your Last Payment : R425</p>
            </div>

            <div className="col float-right my-3">
              <input
                className="form-control"
                type="text"
                name=""
                placeholder="Search Transaction"
                id=""
              />
            </div>
          </div>
          <div id="example5_wrapper" className="dataTables_wrapper no-footer">
            <table
              className="table display mb-4 dataTablesCard short-one card-table text-black dataTable no-footer"
              id="transaction_table"
              role="grid"
              aria-describedby="example5_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Transaction ID: activate to sort column ascending"
                    style={{ width: 124 }}
                  >
                    Date
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Transaction ID
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Status
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Amount
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                {loanrepaymentdata.map((loandata) => (
                  <tr role="row" className="odd">
                    <td>{loandata.transaction_date}</td>

                    <td>
                      <div className="d-flex align-items-center">
                        <span>{loandata.transaction_id}</span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span>{loandata.transaction_status}</span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span>{loandata.transaction_Amount}</span>
                      </div>
                    </td>

                    <td>
                      {loandata.transaction_status === "Due" ? (
                        <button className="btn btn-danger">Pay</button>
                      ) : (
                        <button className="btn btn-success text-white">
                          Details
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
              <div className="dataTables_info">
                Showing {activePag.current * sort + 1} to{" "}
                {data.length > (activePag.current + 1) * sort
                  ? (activePag.current + 1) * sort
                  : data.length}{" "}
                of {data.length} entries
              </div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="example5_paginate"
              >
                <Link
                  className="paginate_button previous disabled"
                  to="/transactions"
                  onClick={() =>
                    activePag.current > 0 && onClick(activePag.current - 1)
                  }
                >
                  Previous
                </Link>
                <span>
                  {paggination.map((number, i) => (
                    <Link
                      key={i}
                      to="/transactions"
                      className={`paginate_button  ${
                        activePag.current === i ? "current" : ""
                      } `}
                      onClick={() => onClick(i)}
                      style={{ display: "inline-block" }}
                    >
                      {number}
                    </Link>
                  ))}
                </span>
                <Link
                  className="paginate_button next"
                  to="/transactions"
                  onClick={() =>
                    activePag.current + 1 < paggination.length &&
                    onClick(activePag.current + 1)
                  }
                >
                  Next
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payments_LoanRepaymentsTab;
