import { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axiosInstance from "../../../../../../services/AxiosInstance";
import { makeStyles, TextField } from "@material-ui/core";
import { pink } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import swal from "sweetalert";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function AddContributionModal(props) {
  const classes = useStyles();
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    if (props.compare) {
      swal({
        icon: "warning",
        title: "Group Expired",
      });
    } else {
      setShow(true);
    }
  };
  const [amount, setAmount] = useState("");
  const [contributionDate, setContributionDate] = useState();
  const [members, setMembers] = useState([]);
  const [memberSelected, setMemberSelected] = useState([]);
  const [checkAll, setCheckAll] = useState(true);
  console.log(memberSelected);
  useEffect(() => {
    getMembers().then((res) => {
      console.log(res?.data?.data);
      setMembers(res?.data?.data);
      setMemberSelected(
        [...Array(res?.data?.data.length)].map(() => {
          return checkAll;
        })
      );
    });
  }, []);

  async function getMembers() {
    const res = await axiosInstance(`/grp_member/getAllGroupMember/${id}`);
    return res?.data;
  }

  const linksendHandle = () => {
    postContribution().then((res) => {
      console.log(res);
      if (res?.success === true) {
        toast.success("Transaction added successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.location.reload();
      }
    });
    setShow(false);
  };

  async function postContribution() {
    try {
      let member = [];
      members.forEach((element, i) => {
        member.push({
          userId: element?.user_id,
          selected: memberSelected[i],
        });
      });
      const res = await axiosInstance.post(`/grp/${id}/contribution`, {
        nextContributionDate: contributionDate,
        amount: amount,
        member: member,
        userId: JSON.parse(localStorage.getItem("userDetails")).userId,
      });
      return res?.data;
    } catch (error) {
      toast.error("Transaction was not added successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  return (
    <div>
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <button
          className="btn btn-primary "
          onClick={handleShow}
          style={{
            background: props.compare ? "grey" : "#3065d0",
            border: "none",
            width: "100%",
          }}
        >
          Add Contribution
        </button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="">Add Contribution</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row d-flex align-items-center flex-column justify-content-around">
              <div className="row ">
                <div className="col">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label className="">
                            Contribution Amount paid by member
                            <br />
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label className="">
                            Select next contribution date
                          </label>
                          <form className={classes.container} noValidate>
                            <TextField
                              id="date"
                              type="date"
                              inputProps={{
                                min: moment(Date.now()).format("YYYY-MM-DD"),
                              }}
                              value={contributionDate}
                              onChangeCapture={(e) =>
                                setContributionDate(e.target.value)
                              }
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </form>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <br />
                          <table className="table  text-center">
                            <tr>
                              <th>
                                <Checkbox
                                  checked={checkAll}
                                  sx={{
                                    color: pink[800],
                                    "&.Mui-checked": {
                                      color: pink[600],
                                    },
                                  }}
                                  onChange={(e) => {
                                    const temp = !checkAll;
                                    setCheckAll(temp);
                                    setMemberSelected(
                                      [...Array(members.length)].map(() => {
                                        return temp;
                                      })
                                    );
                                  }}
                                />
                              </th>
                              <th className="">Select the members</th>
                            </tr>

                            {members.map((row, index) => {
                              return (
                                <>
                                  <tr>
                                    <td className="">
                                      <Checkbox
                                        sx={{
                                          color: pink[800],
                                          "&.Mui-checked": {
                                            color: pink[600],
                                          },
                                        }}
                                        checked={memberSelected[index]}
                                        onChange={(e) => {
                                          setMemberSelected((prev) => {
                                            const temp = [...prev];
                                            temp[index] = !temp[index];
                                            return temp;
                                          });
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <p className="text-center">
                                        {row?.user?.first_name}{" "}
                                        {row?.user?.last_name}
                                      </p>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              CANCEL
            </Button>
            <Button variant="primary" onClick={linksendHandle}>
              SUBMIT
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
  // <button className={"btn btn-primary"}>Add Transaction</button>
}
