import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useLocation, useParams } from "react-router-dom";
import axiosInstance from "../../../../../services/AxiosInstance";
import InviteLinkModal from "./components/InviteLinkModal";
import JoinRequestsModal from "./components/JoinRequestsModal";
import Tooltip from "@mui/material/Tooltip";
import LoanRequestsLink from "./components/LoanRequestsLink";
import moment from "moment";
import ManageTab from "./components/ManageTab";
import AddTransactionModal from "./components/AddTransactionModal";
import AddContributionModal from "./components/AddContributionModal";
import swal from "@sweetalert/with-react";
import AddInvestmentModal from "./components/AddInvestmentModal";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

function ManageGroup() {
  const userid = JSON.parse(localStorage.getItem("userDetails")).userId;
  const [groupdetails, setGroupDetails] = useState();
  const [groupcount, setGroupCount] = useState();
  const [transactions, setTransactions] = useState([]);
  const { id } = useParams();

  const [show, setShow] = useState(false);
  const [editpercent, setEditPercent] = useState(false);
  const [editpercentvalue, setEditPercentValue] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentBalance, setCurrentBalance] = useState();

  const [activeloancount, setActiveLoancount] = useState("");
  const [closedloancount, setClosedLoanAccount] = useState("");

  const data = {
    defaultFontFamily: "Poppins",
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: "My First dataset",
        data: [65, 59, 80, 81, 56, 55, 40],
        borderColor: "rgba(64, 24, 157, 1)",
        borderWidth: "0",
        backgroundColor: "#EB8153",
      },
    ],
  };

  const getActiveLoancount = () => {
    axiosInstance.get(`/loan/getActiveLoan/${id}`).then((res) => {
      console.log("Activeloancount", res);
      setActiveLoancount(res.data.data?.count);
    });
  };

  const getClosedLoancount = () => {
    axiosInstance.get(`/loan/getClosedLoan/${id}`).then((res) => {
      console.log(res.data.data.count);
      setClosedLoanAccount(res.data.data?.count);
    });
  };

  useEffect(() => {
    getActiveLoancount();
    getClosedLoancount();
  }, []);

  const options = {
    legend: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          // Change here
          barPercentage: 0.5,
        },
      ],
    },
  };

  const location = useLocation();
  const getGroupDetails = async () => {
    await axiosInstance
      .get(`/grp/manageUser/${userid}/group/${id}`)
      .then((res) => {
        console.log(res?.data);
        setGroupDetails(res?.data?.data.data);
      });
    await axiosInstance
      .get(`/grp_member/getTotalGrpMember/${id}`)
      .then((groupcountres) => {
        console.log(groupcountres.data.data.count);
        setGroupCount(groupcountres.data.data.count);
      });
  };

  useEffect(() => {
    getGroupDetails();
  }, []);

  useEffect(() => {
    getGroupTransactions().then((res) => {
      setTransactions(res?.data?.dataTrans);
    });
  }, []);

  useEffect(() => {
    getGroupCurrentBalance().then((res) => {
      setCurrentBalance(res?.data?.amount);
    });
  }, []);

  async function getGroupCurrentBalance() {
    const res = await axiosInstance(`/grp/${id}/group_balance`);
    return res?.data;
  }

  async function getGroupTransactions() {
    const res = await axiosInstance(`/grp_trans/group_transaction/${id}`);
    return res?.data;
  }
  const editpercenthandlechange = (e) => {
    let value = e.target.value;
    setEditPercentValue(value);
  };

  const updatePercentageHandle = () => {
    setEditPercent(false);

    let percentage = {
      percentage: editpercentvalue,
      userId: JSON.parse(localStorage.getItem("userDetails")).userId,
    };

    axiosInstance
      .put(`/grp/groupPercentageUpdate/${id}`, percentage)
      .then((res) => {
        console.log(res);

        swal({
          title: "Updated !",
          text: "User Loan Limit Percentage Updated !",
          icon: "success",
          /*     button: "Aww yiss!", */
        }).then((dat) => {
          getGroupDetails();
        });
      });
  };

  console.log(editpercentvalue);

  const expiredata = moment(groupdetails?.group_expire_date);
  const todaydate = moment(new Date());

  console.log("expire Date", expiredata, "today Date", todaydate);

  const compare = expiredata.isBefore(todaydate);

  console.log("compare", compare);

  console.log("expiredate", expiredata.format("dddd, MMMM Do YYYY, h:mm:ss a"));
  console.log("today Date", todaydate.format("dddd, MMMM Do YYYY, h:mm:ss a"));

  return (
    <div className="">
      <div className="my-2">
        <h3>
          Welcome to{" "}
          <span style={{ color: "orange" }}>
            {groupdetails?.group_name.toUpperCase()}
          </span>{" "}
        </h3>

        <div>
          {compare && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#eb8153",
                color: "white",
                borderRadius: "10px",
                fontSize: "26px",
                padding: "10px",
              }}
            >
              <span>This Group Has Been Expired</span>
            </div>
          )}
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-12">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            style={{
              margin: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              /*     pointerEvents: compare ? "" : "none", */
            }}
          >
            {/*         {compare && (
              <div
                style={{
                  position: "absolute",
                  zIndex: "22",
                  height: "148px",
                  border: "1px solid orange",
                  display: "flex",
                  justifyContent: "center",
                  verticalAlign: "middle",
                  alignItems: "center",
                  borderRadius: "10px",
                  color: "orange",
                  width: "100%",
                  background: "#000000b3",
                }}
              >
                <span>
                  Group Has Been Expired, You Can't Able to Do Any Transaction.
                </span>
              </div>
            )} */}

            <Grid item xs={12} sm={12} md={12} lg={4.5}>
              <div className="">
                <button
                  className="btn btn-primary text-white"
                  onClick={handleShow}
                  style={{
                    marginTop: "9px",
                    width: "98%",
                    pointerEvents: "none",
                    background: "#3b3363",
                    border: "none",
                    paddingTop: "23px",
                    paddingBottom: "22px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{ fontSize: "16px", flexWrap: "wrap", width: "250px" }}
                  >
                    Current contribution of all group members <br />
                    <span style={{ fontSize: "19px", color: "orange" }}>
                      {currentBalance?.toLocaleString("fi-FI")}
                    </span>
                  </Box>
                </button>
                {/*                <Box
                  sx={{
                    display: { lg: "block", md: "flex", sm: "flex" },
                    justifyContent: "center",
                    background: "#3b3363",
                    width: "98%",
                    borderRadius: "10px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    alignItems: "center",
                    marginTop: "13px",
                  }}
                >
                  <div
                    className=" style-1 default-select p-0 mt-3 px-2"
                    style={{ display: "inline-grid" }}
                  >
                    <span style={{ fontSize: "16px", marginLeft: "10px" }}>
                      Current contribution of all group members{" "}
                    </span>
                  </div>
                  <div className="style-1 default-select p-0 mt-2 px-4 text-center">
                    <span style={{ fontSize: "19px" }}>{currentBalance}</span>
                  </div>
                </Box> */}
              </div>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={2.5}>
              <div className="my-3">
                {/*        <button
                    type="button"
                    class="btn btn-warning position-relative px-4"
                  >
                    Loan Requests
                    <span class="position-absolute top-0 mx-2 start-100 translate-middle badge rounded-pill bg-info">
                      3
                    </span>
                  </button> */}

                <LoanRequestsLink compare={compare} />
              </div>

              <div className=" my-3">
                {/* <JoinRequestsModal /> */}
                <AddTransactionModal
                  compare={compare}
                  setCurrentBalance={setCurrentBalance}
                  getGroupCurrentBalance={getGroupCurrentBalance}
                />
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={2.5}>
              <div className=" my-3">
                {/* <JoinRequestsModal /> */}
                <AddContributionModal compare={compare} />
              </div>
              <div className="my-3">
                {/* <JoinRequestsModal /> */}
                <AddInvestmentModal compare={compare} />
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={2.5}>
              <div className="my-3" style={{ width: "100%" }}>
                {/*       <button
                    type="button"
                    class="btn btn-success text-white position-relative px-4"
                    onClick={() => <InviteLinkModal clickmodal />}
                  >
                    Invite
                  </button> */}

                <InviteLinkModal groupId={location?.state} compare={compare} />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*         group info and bargraph  */}

        <div className="col-md-12 " style={{ marginTop: "35px" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div>
                <h5>Group Info</h5>
                <div className="card">
                  {/* <div className="card-body col-md-7">
                    <table className="table mx-auto table-borderless display mb-1 dataTablesCard  card-table text-black dataTable no-footer">
                      <tbody>

                      </tbody>
                    </table>

                  </div> */}
                  <div className="container-fluid">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <h4 className="mx-3">{groupdetails?.group_name}</h4>
                        <br />
                        <table className="table">
                          <tr role="" className="">
                            <td>Group Created</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span>
                                  {moment(groupdetails?.createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Group Members</td>
                            <td>{groupcount?.toLocaleString("fi-FI")}</td>
                          </tr>
                          <tr>
                            <td>The members owe in total</td>
                            <td>
                              {groupdetails?.group_outstanding_balance?.toLocaleString(
                                "fi-FI"
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>Member Claim Limit (%)</td>
                            <td>
                              {editpercent ? (
                                <>
                                  <div style={{ display: "flex" }}>
                                    <input
                                      type="text"
                                      classname="form-control"
                                      style={{
                                        width: "65px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        paddingLeft: "5px",
                                        paddingRight: "5px",
                                        fontSize: "18px",
                                      }}
                                      onChange={editpercenthandlechange}
                                      name="editpercentage"
                                      value={editpercentvalue}
                                    />
                                    <button
                                      className="btn-sm"
                                      onClick={updatePercentageHandle}
                                      style={{
                                        background: "yellowgreen",
                                        color: "white",

                                        border: "none",
                                        paddingLeft: "22px",
                                        paddingRight: "22px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                      }}
                                    >
                                      <i
                                        class="fa fa-check fa-2x"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {groupdetails?.percentage}%{"  "}
                                  {"  "}
                                  {!compare && (
                                    <Tooltip
                                      title="Edit Claim Limit (%)"
                                      placement="right"
                                    >
                                      <button
                                        className="btn btn-sm ml-1"
                                        onClick={() => {
                                          setEditPercentValue(
                                            groupdetails?.percentage
                                          );
                                          setEditPercent(true);
                                        }}
                                      >
                                        <i
                                          class="fa fa-pencil-square-o fa-2x text-primary"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Group Expire Data</td>
                            <td>
                              {moment(groupdetails?.group_expire_date).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>Active Loans</td>
                            <td>
                              {activeloancount
                                ? activeloancount
                                : "No Active Loans"}
                            </td>
                          </tr>
                          <tr>
                            <td>Closed Loans</td>
                            <td>
                              {closedloancount
                                ? closedloancount
                                : "No Closed Loans"}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5}>
              {/* <div>
                <h5>Bar Graph</h5>
                <div className="card">
                  <div className="card-header">
                    <p>Bar Graph Info</p>
                  </div>
                  <div className="card-body my-0">
                    <Box sx={{ paddingBottom: { lg: "39px", md: "57px" } }}>
                      <Bar data={data} height={150} options={options} />
                    </Box>
                  </div>
                </div>
              </div> */}
              {/* <div className="card">
                <table className="table mx-auto table-borderless display mb-1 dataTablesCard  card-table text-black dataTable no-footer">
                  <tbody>
                    <tr>
                      <td>Group Expire Data</td>
                      <td>
                        {moment(groupdetails?.group_expire_date).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>Active Loans</td>
                      <td>
                        {activeloancount ? activeloancount : "No Active Loans"}
                      </td>
                    </tr>
                    <tr>
                      <td>Closed Loans</td>
                      <td>
                        {closedloancount ? closedloancount : "No Closed Loans"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </Grid>
          </Grid>

          {/*    <div className=" d-flex  justify-content-around align-it ">
         
      
          </div> */}
        </div>

        {/*      Tab Menus Members, Transactions, Lonas */}
        <div className="col-md-12">
          <ManageTab
            transactions={transactions}
            setTransactions={setTransactions}
            activeMenu="Wallet Activity"
          />
        </div>
      </div>
    </div>
  );
}

export default ManageGroup;
