import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Button,
  ProgressBar,
  Modal,
} from "react-bootstrap";
import axiosInstance from "../../../../../../services/AxiosInstance";
import { ProfileData } from "../../jsonData/profiledata";

function Personal_info() {
  const [userData, setUserData] = useState("");
  const [userEdit, setUserEdit] = useState();
  const [show, setShow] = useState(false);

  const localdata = JSON.parse(localStorage.getItem("userDetails"));
  const userid = localdata.userId;

  console.log(localdata);

  const getSingleUser = () => {
    axiosInstance.get(`/user/userView/${userid}`).then((res) => {
      console.log(res.data.data.user);
      setUserData(res?.data?.data.user);
      setUserEdit(res?.data?.data.user);
    });
  };

  useEffect(() => {
    getSingleUser();
  }, [show]);

  console.log(userData.first_name);

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onEditHandle = (e) => {
    setUserEdit({ ...userEdit, [e.target.name]: e.target.value });
  };

  console.log(userEdit);

  const updateHandle = () => {
    axiosInstance.put(`/user/update/${userid}`, userEdit).then((res) => {
      console.log(res);
      setShow(false);
    });
  };

  console.log(userid);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Edit Personal Information</span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label htmlFor="">First Name</label>
              <input
                type="text"
                value={userEdit?.first_name}
                className="form-control"
                name="first_name"
                id=""
                onChange={onEditHandle}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                value={userEdit?.last_name}
                className="form-control"
                name="last_name"
                id=""
                onChange={onEditHandle}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Email ID</label>
              <input
                type="text"
                className="form-control"
                value={userEdit?.email}
                name="email"
                id=""
                onChange={onEditHandle}
                readOnly={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Phone Number</label>
              <input
                type="text"
                className="form-control"
                value={userEdit?.mobile_number}
                name="mobile_number"
                id=""
                onChange={onEditHandle}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">
                Date of Birth{" "}
                <span style={{ fontSize: "10px", color: "orange" }}>
                  (Cannot Change)
                </span>{" "}
              </label>
              <input
                type="text"
                className="form-control"
                value={moment(userEdit?.dob).format("DD-MMM-YYYY")}
                name="dob"
                id=""
                readOnly
                onChange={onEditHandle}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateHandle}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Card>
        <span style={{ fontSize: "20px" }}>Personal Information</span>

        <Card.Body>
          <Card.Text>
            <div className="row">
              <div className="col-md-12">
                <table className="table table-hover">
                  <tr>
                    <td>First Name</td>
                    <td>{userData.first_name}</td>
                  </tr>
                  <tr>
                    <td>Last Name</td>
                    <td>{userData.last_name}</td>
                  </tr>
                  <tr>
                    <td>Email ID</td>
                    <td>{userData.email}</td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>{userData.mobile_number}</td>
                  </tr>
                  <tr>
                    <td>Date Of Birth</td>
                    <td className="">
                      {moment(userData.dob).format("DD-MMM-YYYY")}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="row ">
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => setShow(true)}
                >
                  Edit
                </button>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Personal_info;
