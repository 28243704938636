import { useState } from "react";
import { Dropdown } from "react-bootstrap";
export default function Reports() {
  return <Report />;
}

function Report() {
  return (
    <div>
      <p className="fs-20 text-black">Welcome to Stokvel</p>
      <p className="fs-20 text-black">Reporting</p>
      <HeadSection />
      <FootSection />
    </div>
  );
}

function HeadSection() {
  
  const [group, setGroup] = useState();
  const handleChange = (event) => {
    setGroup(event);
  };
  return (
    <div>
      <div
        className="card"
        style={{
          padding: "40px",
          marginBottom: "30px",
        }}
      >
        <div className="row">
          <div className="col-sm-12">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "40px",
              }}
            >
              <Dropdown>
                <Dropdown.Toggle variant="var(--purple)" id="dropdown-basic">
                  Select group
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ width: "100%" }}>
                  <Dropdown.Item>
                    <p
                      style={{ textAlign: "center", margin: "0" }}
                      onClick={() => handleChange("1")}
                    >
                      Group 1
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <p
                      style={{ textAlign: "center", margin: "0" }}
                      onClick={() => handleChange("2")}
                    >
                      Group 2
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <p
                      style={{ textAlign: "center", margin: "0" }}
                      onClick={() => handleChange("3")}
                    >
                      Group 3
                    </p>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-6">
                    <div
                      className="card"
                      style={{
                        padding: "20px",
                        backgroundColor: "var(--primary)",
                      }}
                    >
                      <p
                        className="text-black"
                        style={{ width: "auto", margin: "0" }}
                      >
                        Total Group Members
                      </p>
                      <p
                        className="text-black"
                        style={{ width: "auto", margin: "0" }}
                      >
                        100
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="card"
                      style={{
                        padding: "20px",
                        backgroundColor: "var(--primary)",
                      }}
                    >
                      <p
                        className="text-black"
                        style={{ width: "auto", margin: "0" }}
                      >
                        Active loans
                      </p>
                      <p
                        className="text-black"
                        style={{ width: "auto", margin: "0" }}
                      >
                        5
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="card"
                      style={{
                        padding: "20px",
                        backgroundColor: "var(--primary)",
                      }}
                    >
                      <p
                        className="text-black"
                        style={{ width: "auto", margin: "0" }}
                      >
                        Total loan Claimed
                      </p>
                      <p
                        className="text-black"
                        style={{ width: "auto", margin: "0" }}
                      >
                        4521
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="card"
                      style={{
                        padding: "20px",
                        backgroundColor: "var(--primary)",
                      }}
                    >
                      <p
                        className="text-black"
                        style={{ width: "auto", margin: "0" }}
                      >
                        Average
                      </p>
                      <p
                        className="text-black"
                        style={{ width: "auto", margin: "0" }}
                      >
                        15425
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div
                  className="row"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div className="col-sm-6">
                    <p
                      style={{
                        width: "auto",
                        margin: "0",
                        textAlign: "center",
                      }}
                      className="text-black fs-20 mb-0"
                    >
                      Total Investment
                    </p>
                    <p
                      style={{
                        width: "auto",
                        margin: "0",
                        textAlign: "center",
                      }}
                      className="text-black fs-20 mb-0"
                    >
                      R45265
                    </p>
                  </div>
                  <div className="col-sm-6">
                    <p
                      style={{
                        width: "auto",
                        margin: "0",
                        textAlign: "center",
                      }}
                      className="text-black fs-20 mb-0"
                    >
                      Total Due
                    </p>
                    <p
                      style={{
                        width: "auto",
                        margin: "0",
                        textAlign: "center",
                      }}
                      className="text-black fs-20 mb-0"
                    >
                      R45265
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function FootSection() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [group, setGroup] = useState();
  const handleChange = (event) => {
    setGroup(event.target.value);
  };
  return (
    <div>
      <div className="card" style={{ padding: "40px" }}>
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-6">
                <button
                  className={
                    selectedTab === 0
                      ? "btn btn-primary"
                      : "btn btn-outline-light"
                  }
                  style={{ marginRight: "20px" }}
                  onClick={(e) => setSelectedTab(0)}
                >
                  Member Details
                </button>
                <button
                  className={
                    selectedTab === 1
                      ? "btn btn-primary"
                      : "btn btn-outline-light"
                  }
                  onClick={(e) => setSelectedTab(1)}
                >
                  Transaction Details
                </button>
              </div>
              <div className="col-sm-6">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="var(--purple)"
                      id="dropdown-basic"
                    >
                      Export
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: "100%" }}>
                      <Dropdown.Item>
                        <p
                          style={{ textAlign: "center", margin: "0" }}
                          onClick={() => handleChange("pdf")}
                        >
                          PDF
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <p
                          style={{ textAlign: "center", margin: "0" }}
                          onClick={() => handleChange("jpeg")}
                        >
                          JPEG
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <p
                          style={{ textAlign: "center", margin: "0" }}
                          onClick={() => handleChange("word")}
                        >
                          Word
                        </p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div style={{ padding: "20px" }}>
              {selectedTab === 0 ? <MemberDetailsTab /> : ""}
              {selectedTab === 1 ? <TransactionDetailsTab /> : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function MemberDetailsTab() {
  const data = [
    {
      sNo: "1",
      memberName: "Akshay",
      email: "akshay@gmail.com",
      contactNo: "999999999",
      userRole: "Member",
      joinedDate: "21/02/22",
      investedAmount: "R4525",
      totalDue: "R412",
      loanStatus: "Active",
      loanClaimed: "R4112",
      loanOutstanding: "R415",
    },
  ];
  return (
    <div className="card col-sm-12" style={{ marginTop: "40px" }}>
      <div className="table-responsive">
        <table className="table shadow-hover card-table border-no tbl-btn text-center">
          <thead>
            <tr>
              <th>S.NO</th>
              <th>Member Name</th>
              <th>Email</th>
              <th>Contact No.</th>
              <th>User Role</th>
              <th>Joined Date</th>
              <th>Invested Amount</th>
              <th>Total Due</th>
              <th>Loan Status</th>
              <th>Loan Claimed</th>
              <th>Loan Outstanding</th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => (
              <tr key={index}>
                <td>{val.sNo}</td>
                <td>{val.memberName}</td>
                <td>{val.email}</td>
                <td>{val.contactNo}</td>
                <td>{val.userRole}</td>
                <td>{val.joinedDate}</td>
                <td>{val.investedAmount}</td>
                <td>{val.totalDue}</td>
                <td>{val.loanStatus}</td>
                <td>{val.loanClaimed}</td>
                <td>{val.loanOutstanding}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function TransactionDetailsTab() {
  const data = [
    {
      date: "25/02/21",
      transactionId: "#45265412521",
      memberName: "Akshay Kumar",
      amount: "450",
      transactionType: "Loan",
    },
    {
      date: "25/02/21",
      transactionId: "#45265412521",
      memberName: "Ranvir Singh",
      amount: "125",
      transactionType: "INVESTMENT",
    },
  ];
  return (
    <div className="card col-sm-12" style={{ marginTop: "40px" }}>
      <div className="table-responsive">
        <table className="table shadow-hover card-table border-no tbl-btn text-center">
          <thead>
            <tr>
              <th>Date</th>
              <th>Transaction ID</th>
              <th>Member Name</th>
              <th>Amount</th>
              <th>Transaction Type</th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => (
              <tr key={index}>
                <td>{val.date}</td>
                <td>{val.transactionId}</td>
                <td>{val.memberName}</td>
                <td>{val.amount}</td>
                <td>{val.transactionType}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
