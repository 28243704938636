import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import axiosInstance from "../../../../../../services/AxiosInstance";
function InviteLinkModal({ clickmodal, groupId, compare }) {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [showlink, setShowLink] = useState(false);
  const [copy, setCopy] = useState("COPY");
  const [email, setEmail] = useState("");
  const handleClose = () => {
    setShow(false);
    setShowLink(false);
    setCopy("COPY");
  };
  const handleShow = () => {
    if (compare) {
      swal({
        icon: "warning",
        title: "Group Expired",
      });
    } else {
      setShow(true);
    }
  };

  const linksendHandle = () => {
    postInvitationByEmail().then((res) => {
      if (res === true) {
        setEmail("");
        toast.success("Group invitation sent.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Invitation link was not sent.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
    setShow(false);
  };

  async function postInvitationByEmail() {
    try {
      const res = await axiosInstance.post(`/grp/inviteEmail/${id}`, {
        email: email,
        /*  group_id: `${id}`, */
        userId: JSON.parse(localStorage.getItem("userDetails")).userId,
      });
      return true;
    } catch (e) {
      return false;
    }
  }
  return (
    <div>
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <button
          style={{
            width: "100%",
            background: compare ? "grey" : "#68cf29",
            border: "none",
          }}
          className="btn btn-success "
          onClick={handleShow}
        >
          Invite
        </button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="">Send Invitation Link</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row d-flex align-items-center flex-column justify-content-around   ">
              {/*        <div className="center my-2">
                <button
                  className="btn btn-info text-center "
                  onClick={() => setShowLink(true)}
                >
                  GENERATE INVITATION LINK
                </button>
              </div>

              {showlink ? (
                <div className="d-flex">
                  <p className="text-primary">
                    https://stokvel.com/invite/p233
                  </p>
                  <p
                    className="mx-3 "
                    onClick={() => setCopy("Copied")}
                  >
                    {copy}
                  </p>
                </div>
              ) : (
                ""
              )} */}

              <div className="col my-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              CANCEL
            </Button>
            <Button variant="primary" onClick={linksendHandle}>
              SEND
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}

export default InviteLinkModal;
