import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import table1 from "../../../../../../images/table/Untitled-1.jpg";
import axiosInstance from "../../../../../../services/AxiosInstance";
import membersloans from "./membersloansdata.json";
import moment from "moment";
// pagination imports
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import TablePagination from "@mui/material/TablePagination";
import avatarsample from "../../../../../../images/avatar/emptyphoto.png";
function LoansTab() {
  const membersdata = membersloans;
  console.log(membersdata);

  const { id } = useParams();

  console.log("loanstab", id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loandata, setLoanData] = useState([]);

  const [data, setData] = useState(
    document.querySelectorAll("#transaction_table tbody tr")
  );
  //const [activeName, setActiveName] = useState("Newest");
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#transaction_table tbody tr"));
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  const getLoansData = () => {
    axiosInstance.get(`/loan/getLoanTakenList/${id}`).then((res) => {
      console.log(res.data.data.loanData);
      setLoanData(res.data.data.loanData);
    });
  };
  useEffect(() => {
    getLoansData();
  }, []);

  // pagination code
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - loandata.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 3.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          style={{
            color: "white",
            borderRadius: "10px",
            background: page === 0 ? "#5b5288" : "#eb8153",
            marginLeft: "8px",
          }}
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background: page === 0 ? "#5b5288" : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background:
              page >= Math.ceil(count / rowsPerPage) - 1
                ? "#5b5288"
                : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background:
              page >= Math.ceil(count / rowsPerPage) - 1
                ? "#5b5288"
                : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const [searchResult, setSearchResult] = useState({});
  const lowersearch = searchResult?.result?.toString().toLowerCase().trim();
  const usersearchall = loandata
    ?.filter(
      (sv) =>
        sv?.user?.first_name.toLowerCase().includes(lowersearch) ||
        sv?.loan_status.toLowerCase().includes(lowersearch) ||
        sv?.loan_amount?.toString().toLowerCase().includes(lowersearch) ||
        sv?.loan_outstanding?.toString().toLowerCase().includes(lowersearch)||
        sv?.next_contribution?.toString().toLowerCase().includes(lowersearch)||
        sv?.createdAt?.toString().toLowerCase().includes(lowersearch)
    )
    .map((sitem) => {
      return sitem;
    });

  console.log(usersearchall);
  const onSearchHandler = (e) => {
    console.log(e.target.value, e.target.value.length);
    setSearchResult({
      result: e.target.value,
      length: e.target.value.length,
    });
  };

  return (
    <div>
      <div className="col-xl-12">
        <div className="table-responsive table-hover fs-14">
          <div className="d-flex row">
            <div className="col-md-12 float-left my-1">
              <div className="col-md-5 float-right my-3">
                <input
                  className="form-control"
                  type="text"
                  name=""
                  onChange={onSearchHandler}
                  placeholder="Search Loan"
                  id=""
                />
              </div>
            </div>
          </div>
          <div id="example5_wrapper" className="dataTables_wrapper no-footer">
            <table
              className="table display mb-4 dataTablesCard short-one card-table text-black dataTable no-footer"
              id="transaction_table"
              role="grid"
              aria-describedby="example5_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Transaction ID: activate to sort column ascending"
                    style={{ width: 124 }}
                  >
                    {" "}
                    S.No
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Member Name
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Status
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Amount
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Loan Issued
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Loan Expired
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="From: activate to sort column ascending"
                    style={{ width: 62 }}
                  >
                    OutStanding
                  </th>

                  {/* <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="From: activate to sort column ascending"
                    style={{ width: 62 }}
                  >
                    Actions
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {loandata?.length === 0 && (
                  <tr
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <td
                      colSpan={8}
                      style={{ fontSize: "20px", color: "orange" }}
                    >
                      No Data Found
                    </td>
                  </tr>
                )}

                {searchResult.length > 0 ? (
                  <>
                    {usersearchall.length === 0 ? (
                      <>
                        <tr>
                          <td colSpan={5} style={{ textAlign: "center" }}>
                            <span style={{ color: "orange", fontSize: "22px" }}>
                              No Records Found
                            </span>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>{""}</>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {searchResult.length > 0 ? (
                  <>
                    {usersearchall?.map((uitem, index) => (
                      <React.Fragment>
                        {usersearchall?.length > 0 ? (
                          <>
                            <tr role="row" className="odd" key={index}>
                              <td>{index + 1}</td>

                              <td>
                                <div className="d-flex align-items-center justify-content-start">
                                  <div>
                                    <img
                                      src={
                                        uitem.user?.image
                                          ? `${process.env.REACT_APP_IMAGE_BASE}/${uitem.user?.image}`
                                          : avatarsample
                                      }
                                      alt=""
                                      className="rounded-circle mr-2 width40 height40"
                                    />
                                  </div>

                                  <div className="d-flex flex-column">
                                    <span>{uitem.user?.first_name}</span>
                                    <span>
                                      {/* {loan.member_rol} */} Member
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span>{uitem?.loan_status}</span>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span>
                                    {uitem?.loan_amount.toLocaleString("fi-FI")}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span>
                                    {moment(uitem?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span>
                                    {moment(uitem?.next_contribution).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span>
                                    {uitem?.loan_outstanding.toLocaleString(
                                      "fi-FI"
                                    )}
                                  </span>
                                </div>
                              </td>
                              {/* <td>
                      <div>
                        <button className="btn btn-primary btn-sm">
                          Details
                        </button>
                      </div>
                    </td> */}
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    {(rowsPerPage > 0
                      ? loandata.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : loandata
                    ).map((loan, index) => (
                      <tr role="row" className="odd" key={index}>
                        <td>{index + 1}</td>

                        <td>
                          <div className="d-flex align-items-center justify-content-start">
                            <div>
                              <img
                                src={
                                  loan.user?.image
                                    ? `${process.env.REACT_APP_IMAGE_BASE}/${loan.user?.image}`
                                    : avatarsample
                                }
                                alt=""
                                className="rounded-circle mr-2 width40 height40"
                              />
                            </div>

                            <div className="d-flex flex-column">
                              <span>{loan.user?.first_name}</span>
                              <span>{/* {loan.member_rol} */} Member</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span>{loan.loan_status}</span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span>
                              {loan.loan_amount.toLocaleString("fi-FI")}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span>
                              {moment(loan.createdAt).format("DD/MM/YYYY")}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span>
                              {moment(loan.next_contribution).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span>
                              {loan.loan_outstanding.toLocaleString("fi-FI")}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                      <div>
                        <button className="btn btn-primary btn-sm">
                          Details
                        </button>
                      </div>
                    </td> */}
                      </tr>
                    ))}
                  </>
                )}
              </tbody>

              {emptyRows > 0 && (
                <tr style={{ height: 41 * emptyRows }}>
                  <td colSpan={8} />
                </tr>
              )}

              <tfoot>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={8}
                  count={loandata.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </tfoot>
            </table>
            {/*       <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
              <div className="dataTables_info">
                Showing {activePag.current * sort + 1} to{" "}
                {data.length > (activePag.current + 1) * sort
                  ? (activePag.current + 1) * sort
                  : data.length}{" "}
                of {data.length} entries
              </div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="example5_paginate"
              >
                <Link
                  className="paginate_button previous disabled"
                  to="/transactions"
                  onClick={() =>
                    activePag.current > 0 && onClick(activePag.current - 1)
                  }
                >
                  Previous
                </Link>
                <span>
                  {paggination.map((number, i) => (
                    <Link
                      key={i}
                      to="/transactions"
                      className={`paginate_button  ${
                        activePag.current === i ? "current" : ""
                      } `}
                      onClick={() => onClick(i)}
                      style={{ display: "inline-block" }}
                    >
                      {number}
                    </Link>
                  ))}
                </span>
                <Link
                  className="paginate_button next"
                  to="/transactions"
                  onClick={() =>
                    activePag.current + 1 < paggination.length &&
                    onClick(activePag.current + 1)
                  }
                >
                  Next
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoansTab;
