import { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axiosInstance from "../../../../../../services/AxiosInstance";
import { makeStyles, TextField } from "@material-ui/core";
import { pink } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { GetInvestmentData } from "../../../../../../store/actions/AdminActions";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
export default function AddInvestmentModal(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    if (props.compare) {
      swal({
        icon: "warning",
        title: "Group Expired",
      });
    } else {
      setShow(true);
    }
  };
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState();
  const [comments, setComments] = useState("");
  const [endDate, setEndDate] = useState();
  const [returnsamount, setReturnsAmount] = useState("");
  let errorsObj = {
    amount: "",
    startDate: null,
    comments: "",
    endDate: null,
    returnsamount: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const linksendHandle = () => {
    setShow(false);
  };

  const validateForm = () => {
    let error = false;
    const errorObj = { ...errorsObj };

    if (amount === "") {
      errorObj.amount = "Please enter your investing amount";
      error = true;
    }
    if (startDate === undefined) {
      errorObj.startDate = "Please select your start date";
      error = true;
    }
    if (comments === "") {
      errorObj.comments = "Please enter your comments";
      error = true;
    }
    if (endDate === undefined) {
      errorObj.endDate = "Please select end date";
      error = true;
    }
    if (returnsamount === "") {
      errorObj.returnsamount = "Please enter your returns amount";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    return error ? false : true;
  };

  const sendInvestmentHandle = () => {
    if (validateForm()) {
      const info = {
        userId: JSON.parse(localStorage.getItem("userDetails")).userId,
        amountInvested: amount,
        returns: returnsamount,
        startDate: startDate,
        endDate: endDate,
        comment: comments,
      };

      axiosInstance.post(`/investment/${id}`, info).then((res) => {
        console.log(res);
        setShow(false);
        swal({
          title: "Added Successfully",
          text: "Investment And Returns Added Successfully!",
          icon: "success",
          /*     button: "Aww yiss!", */
        }).then((dat) => {
          
          dispatch(GetInvestmentData(id));
          window.location.reload();
        });
      });
    }
  };

  return (
    <div>
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <button
          className="btn btn-primary "
          onClick={handleShow}
          style={{
            background: props.compare ? "grey" : "orange",
            border: "none",
            width: "100%",
          }}
        >
          Add Investment
        </button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="">Investment made</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <div className="">
                <div className="">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="">
                        <div className="form-group ">
                          <label className="">
                            Select next contribution date
                          </label>
                          <form className={classes.container} noValidate>
                            <TextField
                              id="date"
                              type="date"
                              inputProps={{
                                min: moment(Date.now()).format("YYYY-MM-DD"),
                              }}
                              value={startDate}
                              onChangeCapture={(e) =>
                                setStartDate(e.target.value)
                              }
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            {errors.startDate && (
                              <div className="text-orange mt-2">
                                {errors.startDate}
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label className="">Investment comments</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Investment comments here"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                          />
                          {errors.comments && (
                            <div className="text-orange mt-2">
                              {errors.comments}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label className="">Select end date</label>
                          <form className={classes.container} noValidate>
                            <TextField
                              id="date"
                              type="date"
                              value={endDate}
                              inputProps={{
                                min: moment(Date.now()).format("YYYY-MM-DD"),
                              }}
                              onChangeCapture={(e) =>
                                setEndDate(e.target.value)
                              }
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </form>
                          {errors.endDate && (
                            <div className="text-orange mt-2">
                              {errors.endDate}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label className="">Amount Invested</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                          {errors.amount && (
                            <div className="text-orange mt-2">
                              {errors.amount}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label className="">Returns received</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter amount"
                            value={returnsamount}
                            onChange={(e) => setReturnsAmount(e.target.value)}
                          />
                          {errors.returnsamount && (
                            <div className="text-orange mt-2">
                              {errors.returnsamount}
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              CANCEL
            </Button>
            <Button variant="primary" onClick={sendInvestmentHandle}>
              SUBMIT
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
  // <button className={"btn btn-primary"}>Add Transaction</button>
}
