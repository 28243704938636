import React, { useState, useEffect, useRef } from "react";

import AdminGroupsList from "./components/AdminGroupsList";
import CreateGroup from "./components/CreateGroup";
import MemberGroupsList from "./components/MemberGroupsList";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function StockvelGroupDashboard() {
  const [data, setData] = useState(
    document.querySelectorAll("#transaction_table tbody tr")
  );
  //const [activeName, setActiveName] = useState("Newest");
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#transaction_table tbody tr"));
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,

          /* background: "transparent", */
        }}
        open={loading}
        onClick={() => {
          setLoading(false);
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <h3>Welcome to Stockvel Group</h3>

      <div className="">
        <div className="">
          <div className="">
            {/*      <button className="btn btn-success text-white d-flex float-right mb-3 mr-4">
              Create A Group
            </button> */}

            <CreateGroup />

            <div className="">
              <div>
                <AdminGroupsList />

                <div className="my-5">
                  <MemberGroupsList setLoading={setLoading} loading={loading} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StockvelGroupDashboard;
