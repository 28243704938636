import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import table1 from "../../../../../images/table/Untitled-1.jpg";
import axiosInstance from "../../../../../services/AxiosInstance";
import sampledatalist from "./sampledata.json";
import moment from "moment";

// pagination imports
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import groupavatar from "../../../../../images/avatar/groupavatar2.png";
import "./styles.css";
import TablePagination from "@mui/material/TablePagination";

import TextField from "@material-ui/core/TextField";
import swal from "sweetalert";
import {
  Modal,
  Dropdown,
  InputGroup,
  Form,
  FormControl,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";
import { Grid } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function MemberGroupsList(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const sampledata = sampledatalist;
  const [getjoinedmembers, setJoinedMembers] = useState([]);
  const [searchResult, setSearchResult] = useState({});
  const [data, setData] = useState(
    document.querySelectorAll("#transaction_table tbody tr")
  );

  const [loanmodalshow, setLoanModalShow] = useState(false);

  //Loan Modal //
  const [period, setPeriod] = useState("");
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [amount, setAmount] = useState();
  const [oneTimeDate, setOneTimeDate] = useState();
  const classes = useStyles();
  const handleChange = (e) => {
    setPeriod(e.target.value);
  };

  const [loangroupid, setLoanGroupId] = useState("");

  const [percentage, setPercentage] = useState();
  const [groupBalance, setGroupBalance] = useState();
  const [amountInvested, setAmountInvested] = useState();
  const [groupdetails, setGroupDetails] = useState("");
  const [showRequestSentDialog, setShowRequestSentDialog] = useState(false);

  console.log("loanamount", amount);

  const handleClose = (success) => {
    if (success) {
      /*      setShow(false); */
      setLoanModalShow(false);
      handleShowRequestSentDialog();
    } else setLoanModalShow(false);
  };
  const handleShowRequestSentDialog = () => setShowRequestSentDialog(true);

  const onChangeGroup = async (e) => {
    console.log("groupid", e.target.value);

    const groupid = e.target.value;

    setLoanGroupId(groupid);

    await axiosInstance.get(`/grp/groupInfo/${groupid}`).then((res) => {
      console.log(res.data.data);
      setGroupDetails(res.data.data);

      axiosInstance(
        `/grp_member/${groupid}/group-member/${JSON.parse(localStorage.getItem("userDetails")).userId
        }/balance`
      ).then((inv) => {
        console.log("amountinvested", inv);
        setAmountInvested(inv.data.data?.amount);

        console.log("sare", inv.data.data?.amount);
      });

      axiosInstance(`/grp/${groupid}/percentage`).then((per) => {
        setPercentage(Number(per.data.data?.percentage));
      });

      axiosInstance(`/grp/${groupid}/group_balance`).then((bal) => {
        setGroupBalance(bal?.data?.data.amount);
      });
    });
  };

  // post loan request

  async function postLoanRequest() {
    try {
      if (amount === undefined || amount === "") {
        swal({
          title: "Failed",
          text: "Please enter an amount!",
          icon: "error",
          /*     button: "Aww yiss!", */
        });
        return;
      }
      if (period === undefined) {
        swal({
          title: "Failed",
          text: "Please select a repayment method",
          icon: "error",
          /*     button: "Aww yiss!", */
        });
        return;
      }
      if (period === "onetime" && oneTimeDate === undefined) {
        swal({
          title: "Failed",
          text: "Please select a repayment method",
          icon: "error",
          /*     button: "Aww yiss!", */
        });
        return;
      }

      const payload = {
        userId: JSON.parse(localStorage.getItem("userDetails")).userId,
        groupId: loangroupid,
        loan_amount: amount,
        repayment: period,
      };

      console.log("payload", payload);
      const res = await axiosInstance.post(`/loan/applyLoan`, payload);
      swal({
        title: "Success",
        text: "Your request for loan is successfull. Admin will contact you shortly.",
        icon: "success",
        /*     button: "Aww yiss!", */
      }).then(function () {
        history.push("/stockvelgroups");

        return res?.data;
      });
    } catch (error) {
      swal({
        title: "Failed",
        text: "There was an error processing the loan.",
        icon: "error",
        /*     button: "Aww yiss!", */
      });
    }
  }

  /*   const getGroupInfo = () => {
    axiosInstance.get(`/grp/groupInfo/${loangroupid}`).then((res) => {
      console.log(res.data.data);
      setGroupDetails(res.data.data);
    });
  };

  useEffect(() => {
    getGroupInfo();
  }, [loangroupid]); */

  /*   useEffect(() => {
    getAmountInvested().then((res) => {
      setAmountInvested(Number(res?.data?.amount));
    });
  }, [loangroupid]); */

  /*   useEffect(() => {
    getBorrowPercentage().then((res) => {
      setPercentage(Number(res?.data?.percentage));
    });
  }, []);
 */
  /*   useEffect(() => {
    getGroupCurrentBalance().then((res) => {
      setGroupBalance(res?.data?.amount);
    });
  }, [loangroupid]); */

  /*   async function getAmountInvested() {
    const res = await axiosInstance(
      `/grp_member/${loangroupid}/group-member/${
        JSON.parse(localStorage.getItem("userDetails")).userId
      }/balance`
    );
    return res?.data;
  }

  async function getBorrowPercentage() {
    const res = await axiosInstance(`/grp/${loangroupid}/percentage`);
    return res?.data;
  }
 */
  /*   async function getGroupCurrentBalance() {
    const res = await axiosInstance(`/grp/${loangroupid}/group_balance`);
    return res?.data;
  } */

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
  };

  console.log("joinedmembers", getjoinedmembers);
  //const [activeName, setActiveName] = useState("Newest");
  const sort = 5;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  const history = useHistory();
  // Active data

  const chageData = (frist, sec) => {
    for (var i = 0; i < data?.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#transaction_table tbody tr"));
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  useEffect(() => {
    getGroups().then((res) => {
      console.log(res);
      getGroupMemberCount(res).then((res) => {
        console.log(res);
        props.setLoading(false);
        setJoinedMembers(res);
      });
    });
  }, []);

  async function getGroups() {
    const res = await axiosInstance(
      `/grp/getGroupJoined/${JSON.parse(localStorage.getItem("userDetails")).userId
      }`
    );
    console.log("joined groups", res?.data?.data?.data);
    return res?.data?.data?.data;
  }

  async function getGroupMemberCount(data) {
    const temp = data?.map(async (row) => {
      const res = await axiosInstance.get(
        `/grp_member/getTotalGrpMember/${row?.group_id}`
      );
      row.memberCount = res?.data?.data?.count;
      return row;
    });
    return Promise.all(temp);
  }

  // pagination code
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - getjoinedmembers.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 3.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          style={{
            color: "white",
            borderRadius: "10px",
            background: page === 0 ? "#5b5288" : "#eb8153",
            marginLeft: "8px",
          }}
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background: page === 0 ? "#5b5288" : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background:
              page >= Math.ceil(count / rowsPerPage) - 1
                ? "#5b5288"
                : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background:
              page >= Math.ceil(count / rowsPerPage) - 1
                ? "#5b5288"
                : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const lowersearch = searchResult?.result?.toString().toLowerCase().trim();

  const usersearchall = getjoinedmembers
    ?.filter((sv) => sv.group?.group_name.toLowerCase().includes(lowersearch))
    .map((sitem) => {
      return sitem;
    });

  console.log(usersearchall);

  const onSearchHandler = (e) => {
    console.log(e.target.value, e.target.value.length);
    setSearchResult({
      result: e.target.value,
      length: e.target.value.length,
    });
  };

  console.log("groupbalance", groupBalance, "percentage", percentage);
  console.log("joinedmembers", getjoinedmembers);
  return (
    <div>
      <Modal
        show={loanmodalshow}
        onHide={() => {
          setLoanModalShow(false);
          setLoanGroupId("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Loan Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="col-sm-12">
              <div style={{ marginBottom: "20px" }}>
                <label htmlFor="">Select Group to Apply Loan</label>
                <select
                  className="form-control"
                  aria-label="Default select example"
                  onChange={onChangeGroup}
                >
                  <option selected>Select Group</option>

                  {getjoinedmembers.map((gr) => (
                    <option value={gr.group_id}>{gr.group?.group_name}</option>
                  ))}
                </select>
              </div>

              {loangroupid && (
                <div
                  className="card"
                  style={{ backgroundColor: "bisque", padding: "20px" }}
                >
                  <p className="text-black" style={{ margin: "0" }}>
                    {/*    Group Name: {amountInvested} */}
                  </p>
                  <p className="text-black" style={{ margin: "0" }}>
                    Invested Amount: {amountInvested}
                  </p>
                  <p
                    className=""
                    style={{ margin: "0", color: "#42a904", fontSize: "16px" }}
                  >
                    Availability to Claim: {(groupBalance * percentage) / 100}
                  </p>
                </div>
              )}
            </div>
            {/*      <div className="col-sm-12">
            <u>
              <p style={{ textAlign: "center" }}>Apply For Claim</p>
            </u>
          </div> */}

            <div
              className="col-sm-12"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="basic-addon1"
                  style={{
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                  }}
                >
                  Amount
                </InputGroup.Text>
                <FormControl
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Please enter an amount."
                />
              </InputGroup>
            </div>
            <div
              className="col-sm-12"
            /*       style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }} */
            >
              <label htmlFor="">Select Repayment Method</label>
              <div className="form-group">
                <select
                  className="form-control"
                  aria-label="Default select example"
                  onChange={handleChange}
                  value={period}
                >
                  <option selected>Please Select Repayment Method</option>
                  <option value="onetime">One time</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
            </div>
            {period === "onetime" ? (
              <div
                className="col-sm-4"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={{ margin: "0" }}>Select a date</p>
              </div>
            ) : (
              ""
            )}
            {period === "onetime" ? (
              <div className="col-sm-8">
                <div
                  className="row"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <div
                    className="col-sm-12"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      id="date"
                      type="date"
                      value={oneTimeDate}
                      onChange={(e) => setOneTimeDate(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: moment(Date.now()).format("YYYY-MM-DD"),
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              if (amount > (groupBalance * percentage) / 100) {
                swal("Amount entered cannot be higher than eligible claim.");
                console.log("failed");
                return;
              }
              postLoanRequest().then((res) => {
                if (res?.success) {
                  toast.success("Loan request sent successfully.", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                } else {
                  toast.error("Loan request failed.", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              });
              handleShowRequestSentDialog();
              handleClose(true);
              setLoanGroupId("");
            }}
            className="btn  text-white"
            style={{ background: "lightseagreen" }}
          >
            Request Loan
          </button>
        </Modal.Footer>
      </Modal>

      <div className="">
        <Grid container md={12} sm={12} xs={12} spacing={2} mb>
          <Grid item md={6} sm={12}>
            <span style={{ fontSize: "19px", color: "maroon" }}>
              You Are a Member of these Groups
            </span>
          </Grid>
          <Grid item md={4} sm={6}>
            <input
              className="form-control"
              type="text"
              name=""
              onChange={onSearchHandler}
              placeholder="Search Group"
              id="searchInput"
            />
          </Grid>
          <Grid item md={2} sm={6}>
            <button
              className="btn btn-success text-white"
              onClick={() => setLoanModalShow(true)}
            >
              Apply Loan
            </button>
          </Grid>
        </Grid>
        <div className="table-responsive table-hover">
          <div id="example5_wrapper" className="dataTables_wrapper no-footer">
            <table
              className="table display mb-4 dataTablesCard short-one card-table text-black dataTable no-footer"
              id="transaction_table"
              role="grid"
              aria-describedby="example5_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Transaction ID: activate to sort column ascending"
                    style={{ width: 124 }}
                  >
                    {" "}
                    S.No
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Group Name
                  </th>
                  {/*      <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="From: activate to sort column ascending"
                    style={{ width: 62 }}
                  >
                    Joined On
                  </th> */}

                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="From: activate to sort column ascending"
                    style={{ width: 62 }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {getjoinedmembers.length === 0 ? (
                  <tr>
                    <td colSpan={5}>
                      <span style={{ color: "orange", fontSize: "22px" }}>
                        You have not Joined any groups
                      </span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {searchResult.length > 0 ? (
                  <>
                    {usersearchall.length === 0 ? (
                      <>
                        <tr>
                          <td colSpan={4} style={{ textAlign: "center" }}>
                            <span style={{ color: "orange", fontSize: "22px" }}>
                              No Records Found
                            </span>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>{""}</>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {searchResult.length > 0 ? (
                  <>
                    {usersearchall?.map((uitem, i) => (
                      <React.Fragment>
                        {usersearchall?.length > 0 ? (
                          <>
                            <tr role="row" className="odd">
                              <td>{i + 1 + 1 * page * rowsPerPage}</td>
                              <td>
                                <div className="d-flex align-items-center justify-content-start">
                                  <div>
                                    <img
                                      src={groupavatar}
                                      alt=""
                                      className="rounded-circle mr-2 width40 height40"
                                    />
                                  </div>

                                  <div className="d-flex flex-column">
                                    <span>{uitem?.group.group_name}</span>
                                    <div style={{ display: "flex" }}>
                                      <span style={{ paddingRight: "5px" }}>
                                        {uitem?.memberCount}{" "}
                                      </span>{" "}
                                      <span style={{ marginLeft: "5px" }}>
                                        {uitem?.memberCount === 1
                                          ? "member"
                                          : "members"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              {/*    <td>
                                <div className="d-flex align-items-center">
                                  <span>
                                    {" "}
                                    {moment(uitem?.created_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </div>
                              </td> */}
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={(e) => {
                                      history.push({
                                        pathname: `/group-dash/${uitem.group_id}`,
                                        state: uitem?.id,
                                      });
                                    }}
                                  >
                                    VIEW
                                  </button>

                                  {/*      <button className="btn btn-warning btn-sm mx-2">
                                    PAYMENTS
                                  </button> */}
                                </div>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    {(rowsPerPage > 0
                      ? getjoinedmembers.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      : getjoinedmembers
                    ).map((admingroup, index) => (
                      <tr role="row" className="odd">
                        <td>{index + 1 + 1 * page * rowsPerPage}</td>
                        <td>
                          <div className="d-flex align-items-center justify-content-start">
                            <div>
                              <img
                                src={groupavatar}
                                alt=""
                                className="rounded-circle mr-2 width40 height40"
                              />
                            </div>
                            {/* test */}
                            <div className="d-flex flex-column">
                              <span>{admingroup?.group?.group_name}</span>
                              <div style={{ display: "flex" }}>
                                <span style={{ paddingRight: "5px" }}>
                                  {admingroup?.memberCount}{" "}
                                </span>
                                <span>
                                  {admingroup?.memberCount === 1
                                    ? "member"
                                    : "members"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        {/*       <td>
                          <div className="d-flex align-items-center">
                            <span>
                              {" "}
                              {moment(admingroup.createdAt).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                          </div>
                        </td> */}
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <button
                              className="btn btn-sm"
                              style={{
                                background: "mediumslateblue",
                                border: "none",
                                color: "white",
                              }}
                              onClick={(e) => {
                                history.push({
                                  pathname: `/group-dash/${admingroup.group_id}`,
                                  state: admingroup?.id,
                                });
                              }}
                            >
                              VIEW
                            </button>

                            {/*     <button className="btn btn-warning btn-sm mx-2">
                              PAYMENTS
                            </button> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>

              {emptyRows > 0 && (
                <tr style={{ height: 41 * emptyRows }}>
                  <td colSpan={5} />
                </tr>
              )}

              <tfoot>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={getjoinedmembers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelRowsPerPage="Rows"
                />
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberGroupsList;
