import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import swal from "sweetalert";
import {
  loadingToggleAction,
  signupAction,
} from "../../store/actions/AuthActions";

// image
import logo from "../../images/stockvellogosfullwhite.png";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import axiosInstance from "../../services/AxiosInstance";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
function Register(props) {
  const [email, setEmail] = useState("");
  let errorsObj = {
    email: "",
    password: "",
    repeatpassword: "",
    first_name: "",
    last_name: "",
    dob: "",
    mobile_number: "",
    address: "",
    city: "",
    country: "",
    postal_code: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [mobile, setMobile] = useState("");
  const [pwderror, setPWDerror] = useState("");
  const [otpmodalshow, setOTPModalShow] = useState("");
  const [otp, setOTP] = useState("");
  const [registerdata, setRegisterData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    dob: "",
    password: "",
    mobile_number: "",
    country: "",
    city: "",
    address: "",
    postal_code: "",
  });

  const [key, setKey] = useState(0);
  const [scrollYSuppressed, setScrollYSuppressed] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const handleClose = () => setOTPModalShow(false);
  const handleShow = () => setOTPModalShow(true);

  function onSignUp(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (registerdata.email === "") {
      errorObj.email = "Please Enter Your Email Id";
      error = true;
    }
    if (registerdata.password === "") {
      errorObj.password = "Please Enter Password";

      error = true;
    }

    if (registerdata.password !== pwd2) {
      errorObj.repeatpassword = "Please Re Enter Password";
      error = true;
    }

    /*     if (registerdata.password !== password) {
      errorObj.repeatpassword = "First Name is Required";
      error = true;
    } */

    if (registerdata.first_name === "") {
      errorObj.first_name = "Please Enter Your First Name";
      error = true;
    }

    if (registerdata.last_name === "") {
      errorObj.last_name = "Please Enter Your Last Name";
      error = true;
    }

    if (registerdata.dob === "") {
      errorObj.dob = "Please Enter Your Date Of Birth";
      error = true;
    }

    if (registerdata.mobile_number === "") {
      errorObj.mobile_number = "Please Enter Your Mobile Number";
      error = true;
    }

    if (registerdata.address === "") {
      errorObj.address = "Please Enter Your Address";
      error = true;
    }

    if (registerdata.city === "") {
      errorObj.city = "Please Enter Your City";
      error = true;
    }

    if (registerdata.country === "") {
      errorObj.country = "Please Enter Your Country";
      error = true;
    }

    if (registerdata.postal_code === "") {
      errorObj.postal_code = "Please Enter Your Postal Code";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    if (!error) {
      axiosInstance
        .post(`/auth/register`, registerdata)
        .then((res) => {
          console.log(res);
          toast.success("OTP Sent To Email. Pls Check");
          setOTPModalShow(true);
        })
        .catch((error) => {
          console.log(error.response.data.errorMessage);

          if (
            error.response.data.errorMessage ===
            "User already exists with same Mobile Number!"
          ) {
            swal(
              "Mobile No. Exists",
              "User Already With Same Mobile",
              "warning"
            );
            console.log("error otp");
          }

          if (
            error.response.data.errorMessage ===
            "User already exists with same email!"
          ) {
            swal("Email Exists", "User Already With Same Email", "warning");
            console.log("error otp");
          }
        });
    }

    /*     dispatch(loadingToggleAction(true));
    dispatch(signupAction(email, password, props.history)); */
  }

  const onChangeHandle = (e) => {
    setRegisterData({ ...registerdata, [e.target.name]: e.target.value });
  };

  console.log(registerdata);

  const verifyOTPhandle = () => {
    if (otp === "") {
      swal({
        title: "Please Enter OTP",
        text: "Enter OTP to Proceed",
        icon: "warning",
        timer: 2000,
      });
    } else {
      const otpdata = {
        otp: otp,
      };
      axiosInstance
        .post(`/auth/verifyOtp`, otpdata, {
          headers: {
            /*      "x-token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJJZCI6MTMsImVtYWlsIjoicmF2aTk5ODhAeW9wbWFpbC5jb20iLCJjcmVhdGVkQXQiOiIyMDIyLTAzLTMxVDA4OjA1OjI5LjkzOVoifSwiaWF0IjoxNjQ4NzEzOTI5fQ.13ZBpAdWczOkff9Ibxwi2LUNh7cu2BYvic1aW3NFfg4", */
          },
        })
        .then((resd) => {
          console.log(resd.data.message);
          if (resd.data.data.message === "User OTP Verify Successfully!") {
            swal({
              title: "Registered Successfully",
              text: "User Successfully Created",
              icon: "success",
              timer: 2000,
            }).then(() => {
              history.push("/login");
            });

            setOTPModalShow(false);

            /*          dispatch(loadingToggleAction(true));
          dispatch(
            signupAction(
              registerdata.email,
              registerdata.password,
              props.history
            )
          ); */
          }
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.status);
          if (error.response.status === 400) {
            swal("Wrong OTP", "Please Enter Correct Password", "warning");
            console.log("error otp");
          }
        });
    }
  };

  return (
    <div>
      <ToastContainer
        /*  position="top-right" */
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Modal show={otpmodalshow} onHide={() => setOTPModalShow(false)} centered>
        <Modal.Header closeButton={handleClose}>
          <Modal.Title>
            {" "}
            <span>Enter Your OTP</span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
            className="form-control"
            placeholder=""
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={verifyOTPhandle}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        className="authincation h-100 p-meddle"
        style={{
          backgroundImage: `url("/static/media/bg-login.ee47a14c.jpg")`,
          backgroundSize: "auto",
        }}
      >
        <div className="container h-100 py-3">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-lg-7 col-sm-12 col-md-7">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    {" "}
                    {/*              <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/login">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Sign up your account</h4>
                    {props.errorMessage && (

                      <div className="">{props.errorMessage}</div>
                    )}
                    {props.successMessage && (
                      <div className="">{props.successMessage}</div>

                    )}
                    <form onSubmit={onSignUp}>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Username</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="username"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Email</strong>
                        </label>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                          placeholder="hello@example.com"
                        />
                      </div>
                      {errors.email && <div>{errors.email}</div>}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <input
                          value={password}

                          onChange={(e) => setPassword(e.target.value)}

                          className="form-control"
                          defaultValue="Password"
                        />
                      </div>
                      {errors.password && <div>{errors.password}</div>}
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Sign me up
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div> */}{" "}
                    <PerfectScrollbar
                    /*    style={{ height: "100vh" }} */
                    /*      options={{ suppressScrollX: scrollYSuppressed }}
                      key={key} */
                    /*     onScrollY={(container) =>
                        console.log(`scrolled to: ${container.scrollTop}.`)
                      }
                      key={key}  */
                    >
                      <div
                        className="auth-form"
                        style={{
                          background: "#3B3363",
                          height: "100vh",
                          overflowY: "auto",
                        }}
                      >
                        <div className="text-center mb-3">
                          <Link to="/login">
                            <img src={logo} alt="" />
                          </Link>
                        </div>
                        <h4 className="text-center mb-4 text-white">
                          Sign up your account
                        </h4>
                        {props.errorMessage && (
                          <div className="">{props.errorMessage}</div>
                        )}
                        {props.successMessage && (
                          <div className="">{props.successMessage}</div>
                        )}
                        <form onSubmit={onSignUp}>
                          <div className="form-group mb-3">
                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <label className="mb-1 text-white">
                                  <strong>First Name</strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  onChange={onChangeHandle}
                                  name="first_name"
                                />
                                <div className="mt-2">
                                  {errors.first_name && (
                                    <div style={{ color: "#eb8153" }}>
                                      {errors.first_name}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-12 mb-3">
                                <label className="mb-1 text-white">
                                  <strong>Last Name</strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  onChange={onChangeHandle}
                                  name="last_name"
                                />
                                <div className="mt-2">
                                  {errors.first_name && (
                                    <div style={{ color: "#eb8153" }}>
                                      {errors.last_name}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 text-white">
                              <strong>Date Of Birth</strong>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="username"
                              onChange={onChangeHandle}
                              name="dob"
                              /*      inputProps={{
                              min: moment(Date.now()).format("YYYY-MM-DD"),
                            }} */

                              max={moment(Date.now()).format("YYYY-MM-DD")}
                            />
                            <div className="mt-2">
                              {errors.first_name && (
                                <div style={{ color: "#eb8153" }}>
                                  {errors.dob}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 text-white">
                              <strong>Email</strong>
                            </label>
                            <input
                              onChange={onChangeHandle}
                              className="form-control"
                              placeholder="hello@example.com"
                              name="email"
                            />
                          </div>
                          {errors.email && (
                            <div style={{ color: "#eb8153" }}>
                              {errors.email}
                            </div>
                          )}
                          <div className="form-group mb-3">
                            <label className="mb-1 text-white">
                              <strong>Password</strong>
                            </label>
                            <div className="input-group transparent-append">
                              <input
                                onChange={onChangeHandle}
                                className="form-control"
                                type={`${showPassword ? "text" : "password"}`}
                                name="password"
                              />

                              <div
                                className="input-group-append"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <span className="input-group-text">
                                    {" "}
                                    <i
                                      className="fa fa-eye"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                ) : (
                                  <span className="input-group-text">
                                    {" "}
                                    <i
                                      className="fa fa-eye-slash"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                            {errors.password && (
                              <div style={{ color: "#eb8153" }}>
                                {errors.password}
                              </div>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 text-white">
                              <strong>Repeat Password</strong>
                            </label>

                            <div className="input-group transparent-append">
                              <input
                                value={pwd2}
                                onChange={(e) => setPwd2(e.target.value)}
                                className="form-control"
                                type={`${showPassword2 ? "text" : "password"}`}
                                name="password"
                              />
                              <div
                                className="input-group-append"
                                onClick={() => setShowPassword2(!showPassword2)}
                              >
                                {showPassword2 ? (
                                  <span className="input-group-text">
                                    {" "}
                                    <i
                                      className="fa fa-eye"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                ) : (
                                  <span className="input-group-text">
                                    {" "}
                                    <i
                                      className="fa fa-eye-slash"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            {errors.repeatpassword && (
                              <div style={{ color: "#eb8153" }}>
                                {errors.repeatpassword}
                              </div>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 text-white">
                              <strong>Mobile</strong>
                            </label>
                            <input
                              onChange={onChangeHandle}
                              className="form-control my-2"
                              placeholder="Enter You Mobile No"
                              name="mobile_number"
                            />
                            <div className="mt-2">
                              {errors.first_name && (
                                <div style={{ color: "#eb8153" }}>
                                  {errors.mobile_number}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 text-white">
                              <strong>Address</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address"
                              onChange={onChangeHandle}
                              name="address"
                            />
                            <div className="mt-2">
                              {errors.address && (
                                <div style={{ color: "#eb8153" }}>
                                  {errors.address}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 text-white">
                              <strong>City</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              onChange={onChangeHandle}
                              name="city"
                            />

                            <div className="mt-2">
                              {errors.city && (
                                <div style={{ color: "#eb8153" }}>
                                  {errors.city}
                                </div>
                              )}
                            </div>

                            <div className="row my-3">
                              <div className="col-md-12 form-group mb-3">
                                <label className="mb-1 text-white">
                                  <strong>Postal Code</strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Postal Code"
                                  onChange={onChangeHandle}
                                  name="postal_code"
                                />

                                <div className="mt-2">
                                  {errors.postal_code && (
                                    <div style={{ color: "#eb8153" }}>
                                      {errors.postal_code}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className=" col-md-12 form-group mb-3">
                                <label className="mb-1 text-white">
                                  <strong>Country</strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Country"
                                  onChange={onChangeHandle}
                                  name="country"
                                />
                                <div className="mt-2">
                                  {errors.country && (
                                    <div style={{ color: "#eb8153" }}>
                                      {errors.country}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Register
                            </button>
                          </div>
                        </form>
                        <div className="new-account mt-3">
                          <p className="">
                            Already have an account?{" "}
                            <Link className="text-primary" to="/login">
                              Sign in
                            </Link>
                          </p>
                        </div>
                      </div>
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);
