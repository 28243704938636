import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import Payments_LoanRepaymentsTab from "./loanrepayments/Payments_LoanRepaymentsTab";
import Payments_MembersDueTab from "./membersduetab/Payments_MembersDueTab";
import PaymentTransactionsTab from "./transactiontab/PaymentTransactionsTab";
function PaymentsDashboard() {
  const [crrency1, setCrrency1] = useState("Select Group Name");

  const tabData = [
    {
      name: "transactions",
    },
    {
      name: "membersdues",
    },
    {
      name: "loanrepayments",
    },
  ];
  return (
    <div>
      <h5>Payments Dashboard</h5>

      <div className="row p-3 my-3">
        <div>
          <div className="card col-md-12">
            <div className="card-header">Current Outstanding</div>
            <div className="card-body">
              <h1 className="text-left">R254</h1>
              <div>
                <button className="btn btn-primary float-right">Pay</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col text-right">
          <button className="btn btn-success text-white">Make Payment</button>
        </div>

        {/*   Select Group field */}
      </div>

      {/*  Transactions, Member Dues, Loan Repayments Tabs */}
      <div className="row">
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                <div className="d-flex justify-content-start">
                  <p className="mx-4">Select Group</p>
                  <Dropdown className="mx-2 ">
                    <Dropdown.Toggle
                      variant=""
                      className="form-control style-1 default-select"
                    >
                      {crrency1}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => setCrrency1("Group Name 12")}
                      >
                        Group Name 12
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setCrrency1("Group Name 13")}
                      >
                        Group Name 13
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setCrrency1("Group Name 14")}
                      >
                        Group Name 14
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Tab.Container defaultActiveKey={tabData[0].name}>
                <Nav as="ul" className="nav-pills mb-4 justify-content-end">
                  <Nav.Item as="li" key="1">
                    <Nav.Link eventKey="transactions">Transactions</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key="2">
                    <Nav.Link eventKey="membersdues">Members Dues</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key="2">
                    <Nav.Link eventKey="loanrepayments">
                      Loan Repayments
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="pt-4">
                  <Tab.Pane eventKey="transactions" key="1">
                    <PaymentTransactionsTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="membersdues" key="2">
                    <Payments_MembersDueTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="loanrepayments" key="3">
                    <Payments_LoanRepaymentsTab />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </div>
  );
}

export default PaymentsDashboard;
