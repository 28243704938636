import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
// import loadable from "@loadable/component";
// import pMinDelay from "p-min-delay";
// import { Dropdown } from "react-bootstrap";
// import ContactSlider from "../zenix/Home/ContactSlider";
import IndexTab from "../zenix/Home/IndexTab";

//Images
// import Card1 from './../../../images/card/card1.jpg';
// import Card2 from './../../../images/card/card2.jpg';
// import Card3 from './../../../images/card/card3.jpg';
// import Card4 from './../../../images/card/card4.jpg';

// const MarketOverviewChart = loadable(() =>
// 	pMinDelay(import("../zenix/Home/MarketOverviewChart"), 1000)
// );
// const CurrentChart = loadable(() =>
// 	pMinDelay(import("../zenix/Home/CurrentChart"), 1000)
// );

// import { ArrowUp, ArrowDown, CoinIcon1, CoinIcon2, CoinIcon3, CoinIcon4 } from "../zenix/Transactions/TableData";
// images
// import table1 from "../../../images/table/Untitled-1.jpg";
// import table2 from "../../../images/table/Untitled-2.jpg";
// import table3 from "../../../images/table/Untitled-3.jpg";
// import CreateGroupModal  from './PopupModal';
//import table4 from "../../../images/table/Untitled-4.jpg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import swal from "sweetalert";
import { Container, Form } from "react-bootstrap";

import { DashTransactionData, StatiticsData } from "./jsonData/dashdata";

import { RangeDatePicker, DatePicker } from "@y0c/react-datepicker";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { ValidationError } from "yup";
import axiosInstance from "../../../services/AxiosInstance";
import moment from "moment";
import DashboardCards from "./components/DashboardCards";
import { Box } from "@mui/material";
// const cardBox = [
// 	{ image: Card1 },
// 	{ image: Card2 },
// 	{ image: Card3 },
// 	{ image: Card4 },
// ];

const orderdataBlog = [
  { price: "82.1", amount: "58.9", total: "134.10" },
  { price: "85.2", amount: "55.8", total: "136,12" },
  { price: "87.3", amount: "53.7", total: "138,12" },
  { price: "89.4", amount: "51.6", total: "139,12" },
  { price: "91.9", amount: "47.1", total: "140,12" },
  { price: "93.8", amount: "46.2", total: "142,12" },
  { price: "94.7", amount: "45.3", total: "145,12" },
  { price: "97.6", amount: "44.4", total: "147,12" },
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  input: {
    "&:valid": {
      backgroundColor: "yellow",
    },
    "&:invalid": {
      backgroundColor: "red",
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  // const [crrency1, setCrrency1] = useState("Monthly (2021)");
  const history = useHistory();
  // const [country1, setCountry1] = useState("Medan, IDN");
  const [data, setData] = useState(
    document.querySelectorAll("#transaction_table tbody tr")
  );
  //const [activeName, setActiveName] = useState("Newest");

  const [test, settest] = useState(0);
  const [show, setShow] = useState(false);
  const [recurringDate, SetRecurringDate] = useState("");
  const [expriryDate, setExpiryDate] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupLimit, setGroupLimit] = useState(null);
  const [monthlyInvestMent, setMonthlyInvestment] = useState(null);
  const [mandatoryRecurringPayment, setMandatoryRecurringPayment] =
    useState(false);
  const [invitations, setInvitations] = useState([]);
  const [loanlimitpercentage, setLoanLimitPercentage] = useState("");

  const [groupnameerror, setGroupNameError] = useState(false);
  const [grouplimiterror, setGroupLimitError] = useState(false);
  const [monthlyinvestmenterror, setMonthlyInvestmentError] = useState(false);
  const [recurringpaymenterror, setRecurringPaymentError] = useState(false);
  const [loanlimiterror, setLoanLimitError] = useState(false);
  const [groupexpireerror, setGroupExpireError] = useState(false);
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#transaction_table tbody tr"));
  }, [test]);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleGroup = () => {
    /*     const flag = createGroupValidation();
    if (flag === false) return;
    setShow(false); */

    if (groupName === "" || groupName === null) {
      setGroupNameError(true);
    } else {
      setGroupNameError(false);
    }
    if (groupLimit === null || groupLimit === "") {
      setGroupLimitError(true);
    } else {
      setGroupLimitError(false);
    }
    if (monthlyInvestMent === null || monthlyInvestMent === "") {
      setMonthlyInvestmentError(true);
    } else {
      setMonthlyInvestmentError(false);
    }
    if (loanlimitpercentage === null || loanlimitpercentage === "") {
      setLoanLimitError(true);
    } else {
      setLoanLimitError(false);
    }

    if (recurringDate === null || recurringDate === "") {
      setRecurringPaymentError(true);
    } else {
      setRecurringPaymentError(false);
    }

    if (expriryDate === null || expriryDate === "") {
      setGroupExpireError(true);
    } else {
      setGroupExpireError(false);
    }

    if (
      groupLimit?.length > 0 &&
      groupnameerror === false &&
      grouplimiterror === false &&
      monthlyinvestmenterror === false &&
      loanlimiterror === false &&
      recurringpaymenterror === false &&
      groupexpireerror === false
    ) {
      postCreateGroup().then((res) => {
        console.log(res?.data?.data?.newGroup);
        postJoinGroup(res?.data?.data?.newGroup).then((res) =>
          console.log(res)
        );
        if (res?.data?.success === true) {
          swal({
            title: "Successfully Created!",
            // text: "You clicked the button!",
            icon: "success",
            button: "Okay!",
          }).then(() => {
            history.push(
              `/stockvelgroups/managegroup/${res?.data?.data?.newGroup?.id}`
            );
          });
        } else {
          swal({
            title: "Failed",
            text: "Try again with different group name",
            icon: "error",
            button: "Okay!",
          });
        }
      });
    }
  };

  function createGroupValidation() {
    if (groupName === "" || groupName === null) {
      /*     swal({
        title: "Failed",
        text: "Group name field was left empty",
        icon: "error",
        button: "Okay!",
      }); */
      setGroupNameError(true);
      return false;
    } else {
      setGroupNameError(false);
    }
    if (groupLimit === null || groupLimit === "") {
      setGroupLimitError(true);
      return false;
    } else {
      setGroupLimitError(false);
    }
    if (monthlyInvestMent === null || monthlyInvestMent === "") {
      /*      swal({
        title: "Failed",
        text: "monthly investment amount was not set",
        icon: "error",
        button: "Okay!",
      }); */
      setMonthlyInvestmentError(true);
      return false;
    } else {
      setMonthlyInvestmentError(false);
    }
    if (loanlimitpercentage === null || loanlimitpercentage === "") {
      /*    swal({
        title: "Failed",
        text: "loan limit was not set",
        icon: "error",
        button: "Okay!",
      }); */
      setLoanLimitError(true);

      return false;
    } else {
      setLoanLimitError(false);
    }
    return true;
  }

  async function postCreateGroup() {
    try {
      const res = await axiosInstance.post(`/grp/createGroup`, {
        group_name: groupName,
        group_limit: groupLimit,
        group_expire_date: expriryDate,
        monthly_investment_amount: monthlyInvestMent,
        userId: JSON.parse(localStorage.getItem("userDetails")).userId,
        role: "created",
        recurring_payment_date: recurringDate,
        percentage: loanlimitpercentage,
      });
      return res;
    } catch (e) {
      swal({
        title: "Failed",
        text: "Try again with different group name",
        icon: "error",
        button: "Okay!",
      });
    }
  }

  async function postJoinGroup(data) {
    try {
      const res = await axiosInstance.post(`/grp_member/createGroupMembers`, {
        groupId: data.id,
        userId: data.user_id,
        group_member_role: "Admin",
        member_involved_by: data.user_id,
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getInvitations().then((res) => {
      if (res?.success === true) {
        setInvitations(res?.data?.inviteGroup);
      }
    });
  }, []);

  async function getInvitations() {
    try {
      const res = await axiosInstance.get(
        `/grp/joinGroupCount/${
          JSON.parse(localStorage.getItem("userDetails")).userId
        }`
      );
      console.log(res?.data);
      return res?.data;
    } catch (e) {
      return e;
    }
  }

  async function acceptInvitation(groupMemberId) {
    const res = await axiosInstance.put(
      `/grp/acceptInvite/${groupMemberId}/accept/`
    );
    if (res?.data?.success) {
      filterInvitations(groupMemberId);
    } else {
      swal({
        title: "Failed",
        text: "Request failed.",
        icon: "error",
        button: "Okay!",
      });
    }
  }

  async function declineInvitation(groupMemberId) {
    const res = await axiosInstance.put(
      `/grp/declineInvite/${groupMemberId}/decline`
    );
    if (res?.data?.success) {
      filterInvitations(groupMemberId);
    } else {
      swal({
        title: "Failed",
        text: "Request failed.",
        icon: "error",
        button: "Okay!",
      });
    }
    filterInvitations(groupMemberId);
  }

  function filterInvitations(groupMemberId) {
    let temp = [...invitations];
    temp = temp.filter((row) => row?.id !== groupMemberId);
    setInvitations([...temp]);
  }

  console.log(recurringDate);

  const datevalues = {
    someDate: "",
  };
  return (
    <Fragment>
      {invitations.map((row) => {
        return (
          <div class="alert alert-success" role="alert">
            <div
              style={{
                marginRight: "0",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ margin: "0" }}>
                You have been invited in {row?.group?.group_name}
              </p>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    lg: "row",
                    xs: "column",
                    md: "row",
                    sm: "column",
                  },
                }}
              >
                <button
                  className="btn btn-success"
                  onClick={(e) => {
                    acceptInvitation(row?.id);
                  }}
                  style={{
                    minWidth: "100px",
                    marginTop: "10px",
                    marginRight: "5px",
                  }}
                >
                  Accept
                </button>
                <button
                  className="btn btn-danger"
                  style={{ marginLeft: "20px" }}
                  onClick={(e) => {
                    declineInvitation(row?.id);
                  }}
                  style={{
                    minWidth: "100px",
                    marginTop: "10px",
                    marginRight: "5px",
                  }}
                >
                  Decline
                </button>
              </Box>
            </div>
          </div>
        );
      })}

      <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
        <h2 className="font-w600 mb-2 mr-auto ">Dashboard </h2>

        {/* <div className="weather-btn mb-2">
						<span className="mr-3 fs-22 font-w600 text-black"><i className="fa fa-cloud mr-2"></i>21</span>
						<Dropdown>
							<Dropdown.Toggle variant="" as="div" className="form-control style-1 default-select mr-3 p-0">{country1}</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => setCountry1("Medan, IDN")}>Medan, IDN</Dropdown.Item>
								<Dropdown.Item onClick={() => setCountry1("Jakarta, IDN")}>Jakarta, IDN</Dropdown.Item>
								<Dropdown.Item onClick={() => setCountry1("Surabaya, IDN")}>Surabaya, IDN</Dropdown.Item>
							 </Dropdown.Menu>
						</Dropdown>
					</div> */}
        {/* <Link to={"#"} className="btn btn-secondary  mb-2"><i className="las la-calendar scale5 mr-3"></i>Filter Periode</Link> */}
        {/* <Link to="/" className="p-2 btn btn-secondary  mb-2"><i className="la la-user-plus la-lg m-2"></i>Create Group</Link> */}
        <Button variant="secondary" onClick={handleShow}>
          Create Group
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create a group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Create group module */}

            <div className="row ">
              <div className="col">
                {/* <div className="card">
									<div className="card-body"> */}
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="">Group Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name your group"
                          value={groupName}
                          onChange={(e) => setGroupName(e.target.value)}
                        />
                        <div>
                          {groupnameerror && (
                            <div>
                              <span
                                style={{
                                  color: "orange",
                                  fontSize: "13px",
                                }}
                              >
                                Please Enter Group Name
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="">Group Limit:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Group Limit"
                          value={groupLimit}
                          onChange={(e) => {
                            if (isNaN(e.target.value)) {
                              return;
                            } else {
                              setGroupLimit(e.target.value);
                            }
                          }}
                        />
                        {/* <select
															defaultValue={"option"}
															className="form-control  mb-0"
															id="sel1"
														>
															<option value="option" disabled> --</option>
															<option>15</option>
															<option>25</option>
															<option>30</option>
															<option>50</option>
															<option>100</option>
														</select> */}
                        {grouplimiterror && (
                          <div>
                            <span
                              style={{
                                color: "orange",
                                fontSize: "13px",
                              }}
                            >
                              Please Enter Group Limit
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="">Monthly Investment</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter amount"
                          value={monthlyInvestMent}
                          onChange={(e) => {
                            if (isNaN(e.target.value)) {
                              return;
                            } else {
                              setMonthlyInvestment(e.target.value);
                            }
                          }}
                        />

                        <div>
                          {monthlyinvestmenterror && (
                            <div>
                              <span
                                style={{
                                  color: "orange",
                                  fontSize: "13px",
                                }}
                              >
                                Please Enter Monthly Investment Amount
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group col-md-6">
                        <label className="">Loan Limit %</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Loan Limit Percentage"
                          value={loanlimitpercentage}
                          onChange={(e) => {
                            if (isNaN(e.target.value)) {
                              return;
                            } else {
                              setLoanLimitPercentage(e.target.value);
                            }
                          }}
                        />

                        <div>
                          {loanlimiterror && (
                            <div>
                              <span
                                style={{
                                  color: "orange",
                                  fontSize: "13px",
                                }}
                              >
                                Please Enter Loan Limit Percentage
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="">Recurring Payment Date</label>
                        {/* <DatePicker/> */}
                        <form className={classes.container} noValidate>
                          <TextField
                            id="date"
                            type="date"
                            value={recurringDate}
                            inputProps={{
                              min: moment(Date.now()).format("YYYY-MM-DD"),
                            }}
                            /*         defaultValue={datevalues.someDate} */
                            onChange={(e) => {
                              SetRecurringDate(e.target.value);
                            }}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {recurringpaymenterror && (
                            <div>
                              <span
                                style={{
                                  color: "orange",
                                  fontSize: "13px",
                                }}
                              >
                                Please Select Recurring Payment Date
                              </span>
                            </div>
                          )}
                        </form>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="">Group expire date</label>
                        {/* <DatePicker /> */}
                        <form className={classes.container} noValidate>
                          <TextField
                            id="date"
                            type="date"
                            value={expriryDate}
                            inputProps={{
                              min: moment(Date.now()).format("YYYY-MM-DD"),
                            }}
                            onChangeCapture={(e) =>
                              setExpiryDate(e.target.value)
                            }
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {groupexpireerror && (
                            <div>
                              <span
                                style={{
                                  color: "orange",
                                  fontSize: "13px",
                                }}
                              >
                                Please Select Group Expire Date
                              </span>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                    {/* <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="">Day</label>
                        <select
                          defaultValue={"option"}
                          className="form-control  mb-0"
                          id="sel1"
                        >
                          <option value="option" disabled>
                            {" "}
                            --
                          </option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                          <option>13</option>
                          <option>14</option>
                          <option>15</option>
                          <option>16</option>
                          <option>17</option>
                          <option>18</option>
                          <option>19</option>
                          <option>20</option>
                          <option>21</option>
                          <option>22</option>
                          <option>23</option>
                          <option>24</option>
                          <option>25</option>
                          <option>26</option>
                          <option>27</option>
                          <option>28</option>
                          <option>29</option>
                          <option>30</option>
                          <option>31</option>
                        </select>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="">Month</label>
                        <select
                          defaultValue={"option"}
                          className="form-control  mb-0"
                          id="sel1"
                        >
                          <option value="option" disabled>
                            {" "}
                            --
                          </option>
                          <option>Janauray</option>
                          <option>Feburary</option>
                          <option>March</option>
                          <option>April</option>
                          <option>May</option>
                          <option>June</option>
                          <option>July</option>
                          <option>August</option>
                          <option>September</option>
                          <option>Octobar</option>
                          <option>November</option>
                          <option>December</option>
                        </select>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="">Year</label>
                        <select
                          defaultValue={"option"}
                          className="form-control  mb-0"
                          id="sel1"
                        >
                          <option value="option" disabled>
                            {" "}
                            --
                          </option>
                          <option>2022</option>
                          <option>2023</option>
                          <option>2024</option>
                          <option>2025</option>
                          <option>2026</option>
                          <option>2027</option>
                        </select>
                      </div>
                    </div> */}
                    {/*            <div className="form-row d-flex justify-content-between mt-4 mb-2">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox ml-1 ">
                          <input
                            type="checkbox"
                            className="custom-control-input mr-2"
                            id="basic_checkbox_1"
                            value={mandatoryRecurringPayment}
                            onChange={(e) =>
                              setMandatoryRecurringPayment(e.target.value)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="basic_checkbox_1"
                          >
                            Mandatory Recurring Payment
                          </label>
                        </div>
                      </div>
                    </div> */}
                  </form>
                </div>
                {/* </div>
								</div> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="btn btn-primary" onClick={handleGroup}>
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* transaction table */}
      {/* style={{
										display: "block",
										height: "200px",
										overflow: "auto"
									}} */}

      <DashboardCards />

      {/*       <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive table-hover fs-14">
            <div id="example5_wrapper" className="dataTables_wrapper no-footer">
              <table
                className="table mb-4 dataTablesCard short-one card-table text-black dataTable no-footer"
                id="transaction_table"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
            
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date: activate to sort column ascending"
                      style={{ width: "54.6667px" }}
                    >
                      Date
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Transaction ID: activate to sort column ascending"
                      style={{ width: 124 }}
                    >
                      {" "}
                      Transaction ID
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="From: activate to sort column ascending"
                      style={{ width: 62 }}
                    >
                      From
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="To: activate to sort column ascending"
                      style={{ width: 110 }}
                    >
                      {" "}
                      To
                    </th>
                 
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Amount: activate to sort column ascending"
                      style={{ width: "58.6667px" }}
                    >
                      Amount
                    </th>
         
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Status: activate to sort column ascending"
                      style={{ width: "99.6667px" }}
                    >
                      Status
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Status: activate to sort column ascending"
                      style={{ width: "99.6667px" }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {DashTransactionData.length ? (
                    DashTransactionData.map((Data, i) => {
                      return (
                        <tr role="row" className="odd">
                        
                          <td>{Data.date}</td>
                          <td>{Data.transaction_id}</td>
                          <td>{Data.from}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                src={Data.icon}
                                alt=""
                                className="rounded-circle mr-2 width40 height40"
                              />
                              <span>{Data.to}</span>
                            </div>
                          </td>
                     
                          <td>
                            <span className="text-black font-w600">
                              ₹{Data.amount}
                            </span>
                          </td>
                  
                          <td>
                            <Link
                              to="/transactions"
                              className={`btn-link float-right ${Data.text_color}`}
                            >
                              {Data.status}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={"#"}
                              className="btn btn-primary "
                            >
                              view
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <th colspan="7">"Data not found"</th>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}

      {/*       <div className="row">
        <div className="col-sm-6">
          <IndexTab />
        </div>

        {StatiticsData.map((data, i) => {
          return (
            <div className="card col-sm-6 ">
              <div className="card-title mt-4">
                <h3>Statistics</h3>
              </div>

              <div className="card shadow mt-2 border text-center mb-1">
                <div className="row ">
                  <div className="col-sm-6 mt-2">
                    <a href="#" class="card-link">
                      No Of Groups
                    </a>
                  </div>
                  <div className="col-sm-6 mt-2 mb-2">
                    <a href="#" class="card-link">
                      {data.total_groups}
                    </a>
                  </div>
                </div>
              </div>
              <div className="card shadow mt-2 border text-center mb-1">
                <div className="row ">
                  <div className="col-sm-6 mt-2">
                    <a href="#" class="card-link">
                      Total Invested Amount
                    </a>
                  </div>
                  <div className="col-sm-6 mt-2 mb-2">
                    <a href="#" class="card-link">
                      {data.total_Invested_amount}
                    </a>
                  </div>
                </div>
              </div>
              <div className="card shadow mt-2 border text-center mb-1">
                <div className="row ">
                  <div className="col-sm-6 mt-2">
                    <a href="#" class="card-link">
                      Total Due Amount
                    </a>
                  </div>
                  <div className="col-sm-6 mt-2 mb-2">
                    <a href="#" class="card-link">
                      {data.total_due_amount}
                    </a>
                  </div>
                </div>
              </div>
              <div className="card shadow mt-2 border text-center mb-3">
                <div className="row ">
                  <div className="col-sm-6 mt-2">
                    <a href="#" class="card-link">
                      Total Paid Loan
                    </a>
                  </div>
                  <div className="col-sm-6 mt-2 mb-2">
                    <a href="#" class="card-link">
                      {data.total_loan_paid}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
    </Fragment>
  );
};
export { orderdataBlog };
export default Home;
