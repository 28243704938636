import { useState } from "react";
import React from "react";
import {
  Dropdown,
  FormControl,
  InputGroup,
  Form,
  Modal,
} from "react-bootstrap";
export default function LoanRequestContainer() {
  const [period, setPeriod] = useState("");
  const [checked, setChecked] = useState(true);
  // const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setPeriod(event);
  };

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div
      style={{
        width: "100%",
        height: "900px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="card"
        style={{ width: "400px", height: "auto", padding: "40px" }}
      >
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="col-sm-12">
            <div
              className="card"
              style={{ backgroundColor: "var(--primary)", padding: "20px" }}
            >
              <p className="text-black" style={{ margin: "0" }}>
                Invested Amount: 8545
              </p>
              <p className="text-black" style={{ margin: "0" }}>
                Availability to Claim: 4251
              </p>
            </div>
          </div>
          <div className="col-sm-12">
            <u>
              <p style={{ textAlign: "center" }}>Apply For Claim</p>
            </u>
          </div>

          <div
            className="col-sm-12"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <InputGroup className="mb-3">
              <InputGroup.Text
                id="basic-addon1"
                style={{
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                }}
              >
                Amount
              </InputGroup.Text>
              <FormControl placeholder="Please enter an amount." />
            </InputGroup>
          </div>
          <div
            className="col-sm-12"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Dropdown>
              <Dropdown.Toggle variant="var(--purple)" id="dropdown-basic">
                Repayment Method
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "100%" }}>
                <Dropdown.Item>
                  <p
                    style={{ textAlign: "center", margin: "0" }}
                    onClick={() => handleChange("monthly")}
                  >
                    Monthly
                  </p>
                </Dropdown.Item>
                <Dropdown.Item>
                  <p
                    style={{ textAlign: "center", margin: "0" }}
                    onClick={() => handleChange("quarterly")}
                  >
                    Quarterly
                  </p>
                </Dropdown.Item>
                <Dropdown.Item>
                  <p
                    style={{ textAlign: "center", margin: "0" }}
                    onClick={() => handleChange("yearly")}
                  >
                    Yearly
                  </p>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            className="col-sm-4"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p style={{ margin: "0" }}>Auto Debit</p>
          </div>
          <div className="col-sm-8">
            <div
              className="row"
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <div
                className="col-sm-3"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    onChange={handleCheckBoxChange}
                  />
                </Form>
              </div>
              <div
                className="col-sm-9"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={{ margin: "0" }}>
                  *will be debited at every interval.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12" style={{ marginTop: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button onClick={handleShow} className="btn btn-primary">
                Request Loan
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="text-light" style={{ margin: "0" }}>
              Loan request sent
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ margin: "0" }}>Please wait for approval</p>
          <p style={{ margin: "0" }}>
            To check the status of your loan
            <span style={{ color: "var(--danger)" }}>click here</span>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
