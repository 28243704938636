import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import KycBanner from "../Kyc/KycBanner";
import {
  Modal,
  Dropdown,
  InputGroup,
  Form,
  FormControl,
} from "react-bootstrap";
import axiosInstance from "../../../services/AxiosInstance";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import avatarsample from "../../../images/avatar/emptyphoto.png";
// pagination imports
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import TablePagination from "@mui/material/TablePagination";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function GroupDashContainer() {
  let kyc = false;
  return (
    <>
      {kyc ? <KycBanner pending={false} /> : ""}
      <DashHead />
      <DashBody />
    </>
  );
}

function DashHead() {
  const { id } = useParams();
  const [currentBalance, setCurrentBalance] = useState();
  const [memberBalance, setMemberBalance] = useState();
  const [nextContributionDate, setNextContributionDate] = useState();
  const [dues, setDues] = useState();
  const [groupdetails, setGroupDetails] = useState("");

  const getGroupInfo = () => {
    axiosInstance.get(`/grp/groupInfo/${id}`).then((res) => {
      console.log(res.data.data);
      setGroupDetails(res.data.data);
    });
  };

  useEffect(() => {
    getGroupInfo();
  }, []);

  useEffect(() => {
    getGroupCurrentBalance().then((res) => {
      setCurrentBalance(res?.data?.amount);
    });
  }, []);

  useEffect(() => {
    getMemberBalance().then((res) => {
      setMemberBalance(res?.data?.amount);
    });
  });

  useEffect(() => {
    getGroup().then((res) => {
      setNextContributionDate(res?.data?.result?.recurring_payment_date);
    });
  });

  useEffect(() => {
    getDues().then((res) => {
      setDues(res?.data?.memberData.member_outstanding_balance);
    });
  });

  async function getGroup() {
    const res = await axiosInstance(`/grp/getSingleGroup/${id}`);
    return res?.data;
  }

  async function getGroupCurrentBalance() {
    const res = await axiosInstance(`/grp/${id}/group_balance`);
    return res?.data;
  }

  async function getMemberBalance() {
    const res = await axiosInstance(
      `/grp_member/${id}/group-member/${
        JSON.parse(localStorage.getItem("userDetails")).userId
      }/balance`
    );
    return res?.data;
  }

  async function getDues() {
    const res = await axiosInstance(
      `/grp_member/getGroupMember/${
        JSON.parse(localStorage.getItem("userDetails")).userId
      }/group/${id}`
    );
    return res?.data;
  }

  const expiredata = moment(groupdetails?.group_expireDate);
  const todaydate = moment(new Date());

  console.log("expire Date", expiredata, "today Date", todaydate);

  const compare = expiredata.isBefore(todaydate);

  console.log("compare", compare);

  return (
    <div className="row">
      <div className="col-sm-12">
        <p>
          Welcome to{" "}
          <span
            style={{ color: "orange", fontWeight: "bold", fontSize: "25px" }}
          >
            {groupdetails?.groupName}
          </span>{" "}
        </p>

        <div>
          {compare && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#eb8153",
                color: "white",
                borderRadius: "10px",
                fontSize: "26px",
                padding: "10px",
              }}
            >
              <span>This Group Has Been Expired</span>
            </div>
          )}
        </div>
      </div>

      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        style={{ padding: "10px" }}
      >
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <div className="">
            <div
              className="card"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: "15px",
              }}
            >
              <div>
                <span>Your contribution in this group</span>
              </div>
              <div>
                <span style={{ fontSize: "25px" }}>
                  {memberBalance?.toLocaleString("fi-FI")}
                </span>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <div className="">
            <div
              className="card"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
                textAlign: "center",
              }}
            >
              <div>
                <span>Total contribution by members</span>
              </div>
              <div>
                <span style={{ fontSize: "25px" }}>
                  {currentBalance?.toLocaleString("fi-FI")}
                </span>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <div className="">
            <div
              className="card"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
                textAlign: "center",
              }}
            >
              <Box sx={{ display: { lg: "block", md: "flex", sm: "flex" } }}>
                <span style={{ color: "orange" }}>The amount</span>
                <br />
                <span style={{ color: "orange", marginLeft: "5px" }}>
                  {" "}
                  You Owe
                </span>
              </Box>

              <div>
                <span style={{ fontSize: "25px", color: "orange" }}>
                  {dues?.toLocaleString("fi-FI")}
                </span>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <div className="">
            <div
              className="card"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
                textAlign: "center",
              }}
            >
              <Box sx={{ display: { lg: "block", md: "flex", sm: "flex" } }}>
                <span style={{ color: "yellowgreen" }}>
                  Next Contribution will be on
                </span>
              </Box>

              <div>
                <span style={{ fontSize: "22px", color: "yellowgreen" }}>
                  {moment(groupdetails.recurringDate).format("DD/MM/YYYY")}
                </span>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function DashBody() {
  const { id } = useParams();
  const [viewState, setViewState] = useState(0);
  const [loanTaken, setLoanTaken] = useState();
  useEffect(() => {
    getSingleLoan().then((res) => {
      console.log(res?.data?.loanData);
      if (res?.data?.loanData) setLoanTaken(true);
      else setLoanTaken(false);
    });
  }, []);

  async function getSingleLoan() {
    try {
      const res = await axiosInstance(
        `/loan/group/${id}/user/${
          JSON.parse(localStorage.getItem("userDetails")).userId
        }`
      );
      // const res = await axiosInstance(`/loan/group/${id}/user/${24}`);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div style={{ marginTop: "20px" }}>
      <div className="row">
        <div className=" col-sm-12">
          <div>
            <div className="row">
              <div className="col-sm-3 my-2">
                <button
                  className={
                    viewState === 0 ? "btn btn-primary" : "btn btn-secondary"
                  }
                  style={{
                    width: "95%",
                  }}
                  onClick={() => setViewState(0)}
                >
                  Group Info
                </button>
              </div>
              <div className="col-sm-3 my-2">
                <button
                  className={
                    viewState === 1 ? "btn btn-primary" : "btn btn-secondary"
                  }
                  style={{
                    width: "95%",
                  }}
                  onClick={() => setViewState(1)}
                >
                  Members
                </button>
              </div>
              <div className="col-sm-3 my-2">
                <button
                  className={
                    viewState === 2 ? "btn btn-primary" : "btn btn-secondary"
                  }
                  style={{
                    width: "95%",
                  }}
                  onClick={() => setViewState(2)}
                >
                  Payments
                </button>
              </div>
              <div className="col-sm-3 my-2">
                <button
                  className={
                    viewState === 3 ? "btn btn-primary" : "btn btn-secondary"
                  }
                  style={{
                    width: "95%",
                  }}
                  onClick={() => setViewState(3)}
                >
                  Loan
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          {viewState === 0 ? <GroupInfoView /> : ""}
          {viewState === 1 ? <MembersView /> : ""}
          {viewState === 2 ? <PaymentsView /> : ""}
          {viewState === 3 ? (
            loanTaken ? (
              <LoanApprovedView />
            ) : (
              <LoanView setViewState={setViewState} />
            )
          ) : (
            ""
          )}
          {viewState === 5 ? <LoanApprovedView /> : ""}
        </div>
      </div>
    </div>
  );
}

function GroupInfoView() {
  const { id } = useParams();
  const [groupinfodata, setGroupInfoData] = useState([]);
  const [groupcount, setGroupCount] = useState();
  const getGroupInfo = () => {
    axiosInstance.get(`/grp/groupInfo/${id}`).then((res) => {
      console.log(res.data.data);
      setGroupInfoData(res.data.data);
    });
  };

  const getCount = () => {
    axiosInstance.get(`/grp_member/getTotalGrpMember/${id}`).then((res) => {
      console.log(res.data.data?.count);
      setGroupCount(res.data.data?.count);
    });
  };

  useEffect(() => {
    getGroupInfo();
    getCount();
  }, []);

  return (
    <div className="card" style={{ marginTop: "40px" }}>
      <div className="card-header">
        <span>Group Details</span>
      </div>
      <div className="row card-body">
        <div className="col-sm-6">
          <p>Group name: {groupinfodata?.groupName} </p>
          <p>
            Group created on:{" "}
            {moment(groupinfodata?.groupMemberJoin).format("DD/MM/YYYY")}
          </p>
          <p>Group members: {groupcount?.toLocaleString("fi-FI")}</p>

          {/*  <p>Last Paid on: 22-01-2022</p> */}
        </div>
      </div>
    </div>
  );
}

function MembersView() {
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [membersdata, setMembersData] = useState([]);

  const [searchResult, setSearchResult] = useState({});

  const getAllMembersByGroup = async () => {
    await axiosInstance
      .get(`/grp_member/getAllGroupMember/${id}`)
      .then((res) => {
        console.log(res.data.data.data);
        setMembersData(res.data.data.data);
      });
  };

  useEffect(() => {
    getAllMembersByGroup();
  }, []);

  // pagination code
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - membersdata.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 3.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          style={{
            color: "white",
            borderRadius: "10px",
            background: page === 0 ? "#5b5288" : "#eb8153",
            marginLeft: "8px",
          }}
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background: page === 0 ? "#5b5288" : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background:
              page >= Math.ceil(count / rowsPerPage) - 1
                ? "#5b5288"
                : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background:
              page >= Math.ceil(count / rowsPerPage) - 1
                ? "#5b5288"
                : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const lowersearch = searchResult?.result?.toString().toLowerCase().trim();
  const usersearchall = membersdata
    ?.filter(
      (sv) =>
        sv.user.first_name.toLowerCase().includes(lowersearch) ||
        sv.user.last_name.toLowerCase().includes(lowersearch) ||
        sv.member_balance.toString().includes(lowersearch)
    )
    .map((sitem) => {
      return sitem;
    });
  console.log(usersearchall);
  const onSearchHandler = (e) => {
    console.log(e.target.value, e.target.value.length);
    setSearchResult({
      result: e.target.value,
      length: e.target.value.length,
    });
  };

  return (
    <div className="" style={{ marginTop: "40px" /* padding: "40px" */ }}>
      <div className="table-responsive table-hover fs-14">
        <div className="d-flex row">
          <div className="col-md-12 float-left mx-2 my-3">
            <div className="col-md-5 float-right my-3">
              <input
                className="form-control "
                type="text"
                name=""
                onChange={onSearchHandler}
                placeholder="Search Member"
                id="searchInput"
              />
            </div>
          </div>
        </div>
        <div id="example5_wrapper" className="dataTables_wrapper no-footer">
          <table
            className="table display mb-4 dataTablesCard short-one card-table text-black dataTable no-footer"
            id="transaction_table"
            role="grid"
            aria-describedby="example5_info"
          >
            <thead>
              <tr role="row">
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example5"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Transaction ID: activate to sort column ascending"
                  style={{ width: 124 }}
                >
                  {" "}
                  S.No
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example5"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Date: activate to sort column ascending"
                  style={{ width: "54.6667px" }}
                >
                  Member Name
                </th>
                <th
                  className="sorting text-center"
                  tabIndex={0}
                  aria-controls="example5"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Date: activate to sort column ascending"
                  style={{ width: "54.6667px" }}
                >
                  Joined Date
                </th>
                <th
                  className="sorting text-center"
                  tabIndex={0}
                  aria-controls="example5"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Date: activate to sort column ascending"
                  style={{ width: "54.6667px" }}
                >
                  Invested Amount
                </th>
                <th
                  className="sorting text-center"
                  tabIndex={0}
                  aria-controls="example5"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Date: activate to sort column ascending"
                  style={{ width: "54.6667px" }}
                >
                  OutStanding Dues
                </th>
                {/* <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example5"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Date: activate to sort column ascending"
                  style={{ width: "54.6667px" }}
                >
                  Actions
                </th> */}
              </tr>
            </thead>

            <tbody>
              {membersdata?.length === 0 && (
                <tr
                  style={{
                    textAlign: "center",
                  }}
                >
                  <td colSpan={6} style={{ fontSize: "20px", color: "orange" }}>
                    No Data Found
                  </td>
                </tr>
              )}
              {searchResult.length > 0 ? (
                <>
                  {usersearchall.length === 0 ? (
                    <>
                      <tr>
                        <td colSpan={6} style={{ textAlign: "center" }}>
                          <span style={{ color: "orange", fontSize: "22px" }}>
                            No Records Found
                          </span>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>{""}</>
                  )}
                </>
              ) : (
                <></>
              )}

              {searchResult.length > 0 ? (
                <>
                  {usersearchall?.map((uitem, i) => (
                    <React.Fragment>
                      {usersearchall?.length > 0 ? (
                        <>
                          <tr role="row" className="odd">
                            <td>{uitem.id}</td>

                            <td>
                              <div className="d-flex align-items-center justify-content-start">
                                <div>
                                  <img
                                    src={
                                      "/static/media/Untitled-3.483d0297.jpg"
                                    }
                                    alt=""
                                    className="rounded-circle mr-2 width40 height40"
                                  />
                                </div>

                                <div className="d-flex flex-column">
                                  <span>
                                    {uitem.user.first_name +
                                      "" +
                                      uitem.user.last_name}
                                  </span>
                                  <span>{uitem.group_member_role}</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span>{uitem.member_join}</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span>
                                  {uitem.member_balance.toLocaleString("fi-FI")}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span>
                                  {uitem.member_outstanding_balance.toLocaleString(
                                    "fi-FI"
                                  )}
                                </span>
                              </div>
                            </td>

                            {/* <td>
                              <div>
                                <button className="btn btn-primary btn-sm">
                                  Details
                                </button>
                              </div>
                            </td> */}
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <>
                  {(rowsPerPage > 0
                    ? membersdata.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : membersdata
                  ).map((member, index) => (
                    <tr role="row" className="odd">
                      <td>{index + 1 + 1 * page * rowsPerPage}</td>

                      <td>
                        <div className="d-flex align-items-center justify-content-start">
                          <div>
                            <img
                              src={
                                member.user?.image
                                  ? `${process.env.REACT_APP_IMAGE_BASE}/${member.user?.image}`
                                  : avatarsample
                              }
                              alt=""
                              className="rounded-circle mr-2 width40 height40"
                            />
                          </div>

                          <div className="d-flex flex-column">
                            <span>
                              {member.user?.first_name +
                                " " +
                                member.user?.last_name}
                            </span>
                            <span>{member?.group_member_role}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <span>
                            {moment(member?.member_join).format("DD/MM/YYYY")}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <span>
                            {member?.member_balance.toLocaleString("fi-FI")}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <span>
                            {member?.member_outstanding_balance.toLocaleString(
                              "fi-FI"
                            )}
                          </span>
                        </div>
                      </td>

                      {/* <td>
                        <div>
                          <button className="btn btn-primary btn-sm">
                            Details
                          </button>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </>
              )}
            </tbody>

            {emptyRows > 0 && (
              <tr style={{ height: 41 * emptyRows }}>
                <td colSpan={5} />
              </tr>
            )}

            <tfoot>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={10}
                count={membersdata.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </tfoot>
          </table>
          {/*    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              Showing {activePag.current * sort + 1} to{" "}
              {data.length > (activePag.current + 1) * sort
                ? (activePag.current + 1) * sort
                : data.length}{" "}
              of {data.length} entries
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate"
            >
              <Link
                className="paginate_button previous disabled"
                to="/transactions"
                onClick={() =>
                  activePag.current > 0 && onClick(activePag.current - 1)
                }
              >
                Previous
              </Link>
              <span>
                {paggination.map((number, i) => (
                  <Link
                    key={i}
                    to="/transactions"
                    className={`paginate_button  ${
                      activePag.current === i ? "current" : ""
                    } `}
                    onClick={() => onClick(i)}
                    style={{ display: "inline-block" }}
                  >
                    {number}
                  </Link>
                ))}
              </span>
              <Link
                className="paginate_button next"
                to="/transactions"
                onClick={() =>
                  activePag.current + 1 < paggination.length &&
                  onClick(activePag.current + 1)
                }
              >
                Next
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

function PaymentsView() {
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchResult, setSearchResult] = useState({});

  const [transactiondata, setTransactionData] = useState([]);
  /*   const data = [
    {
      date: "20/01/2022",
      transactionId: "#514525415",
      amount: "200",
      action: "",
    },
    {
      date: "20/01/2022",
      transactionId: "#514525415",
      amount: "200",
      action: "",
    },
    {
      date: "20/01/2022",
      transactionId: "#514525415",
      amount: "200",
      action: "",
    },
    {
      date: "20/01/2022",
      transactionId: "#514525415",
      amount: "200",
      action: "",
    },
    {
      date: "20/01/2022",
      transactionId: "#514525415",
      amount: "200",
      action: "",
    },
  ];
 */
  const getAllTransactionByGroupandUser = () => {
    let user_id = JSON.parse(localStorage.getItem("userDetails")).userId;
    let group_id = id;

    axiosInstance
      .get(`/grp_trans/getAllTranscByGroup/${user_id}/group/${group_id}`)
      .then((res) => {
        console.log(res);
        setTransactionData(res.data.data);
      });
  };

  useEffect(() => {
    getAllTransactionByGroupandUser();
  }, []);

  // pagination code
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - transactiondata.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 3.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          style={{
            color: "white",
            borderRadius: "10px",
            background: page === 0 ? "#5b5288" : "#eb8153",
            marginLeft: "8px",
          }}
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background: page === 0 ? "#5b5288" : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background:
              page >= Math.ceil(count / rowsPerPage) - 1
                ? "#5b5288"
                : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background:
              page >= Math.ceil(count / rowsPerPage) - 1
                ? "#5b5288"
                : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const lowersearch = searchResult?.result?.toString().toLowerCase().trim();

  const usersearchall = transactiondata
    ?.filter(
      (sv) =>
        sv.transaction_id.toLowerCase().includes(lowersearch) ||
        sv.type.toLowerCase().includes(lowersearch) ||
        sv.amount.toString().includes(lowersearch)
    )
    .map((sitem) => {
      return sitem;
    });

  console.log(usersearchall);

  const onSearchHandler = (e) => {
    console.log(e.target.value, e.target.value.length);
    setSearchResult({
      result: e.target.value,
      length: e.target.value.length,
    });
  };

  return (
    <div>
      <div className="" style={{ marginTop: "40px" /* padding: "40px" */ }}>
        <div className="table-responsive table-hover fs-14">
          <div className="d-flex row">
            <div className="col-md-12 float-left mx-2 my-3">
              <div className="col-md-5 float-right my-3">
                <input
                  className="form-control "
                  type="text"
                  name=""
                  onChange={onSearchHandler}
                  placeholder="Search Transaction"
                  id="searchInput"
                />
              </div>
            </div>
          </div>
          <div id="example5_wrapper" className="dataTables_wrapper no-footer">
            <table
              className="table display mb-4 dataTablesCard short-one card-table text-black dataTable no-footer"
              id="transaction_table"
              role="grid"
              aria-describedby="example5_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Transaction ID: activate to sort column ascending"
                    style={{ width: 124 }}
                  >
                    {" "}
                    Date
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Transaction ID
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Amount
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Transaction Type
                  </th>

                  {/* <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{ width: "54.6667px" }}
                  >
                    Actions
                  </th> */}
                </tr>
              </thead>

              <tbody>
                {transactiondata?.length === 0 && (
                  <tr
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <td
                      colSpan={6}
                      style={{ fontSize: "20px", color: "orange" }}
                    >
                      No Data Found
                    </td>
                  </tr>
                )}

                {searchResult.length > 0 ? (
                  <>
                    {usersearchall.length === 0 ? (
                      <>
                        <tr>
                          <td colSpan={4} style={{ textAlign: "center" }}>
                            <span style={{ color: "orange", fontSize: "22px" }}>
                              No Records Found
                            </span>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>{""}</>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {searchResult.length > 0 ? (
                  <>
                    {usersearchall?.map((trans, i) => (
                      <React.Fragment>
                        {usersearchall?.length > 0 ? (
                          <>
                            <tr role="row" className="odd">
                              <td>
                                <span className="text-nowrap">
                                  {" "}
                                  {trans?.transaction_date}
                                </span>
                              </td>

                              <td>
                                <span>{trans?.transaction_id}</span>
                              </td>
                              <td>
                                <span>
                                  {trans?.amount.toLocaleString("fi-FI")}
                                </span>
                              </td>
                              <td className="">
                                <span>{trans?.type}</span>
                              </td>

                              {/* <td>
                                <div>
                                  <button className="btn btn-primary btn-sm">
                                    Details
                                  </button>
                                </div>
                              </td> */}
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    {(rowsPerPage > 0
                      ? transactiondata.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : transactiondata
                    ).map((trans) => (
                      <tr role="row" className="odd">
                        <td>
                          <span className="text-nowrap">
                            {" "}
                            {moment(trans?.transaction_date).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </td>

                        <td className="">
                          <span>{trans?.transaction_id}</span>
                        </td>
                        <td className="">
                          <span>{trans?.amount.toLocaleString("fi-FI")}</span>
                        </td>
                        <td className="d-flex justify-content-start">
                          <span>{trans?.type}</span>
                        </td>

                        {/* <td>
                          <div>
                            <button className="btn btn-primary btn-sm">
                              Details
                            </button>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </>
                )}
              </tbody>

              {emptyRows > 0 && (
                <tr style={{ height: 41 * emptyRows }}>
                  <td colSpan={5} />
                </tr>
              )}

              <tfoot>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={10}
                  count={transactiondata.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </tfoot>
            </table>
            {/*    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              Showing {activePag.current * sort + 1} to{" "}
              {data.length > (activePag.current + 1) * sort
                ? (activePag.current + 1) * sort
                : data.length}{" "}
              of {data.length} entries
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate"
            >
              <Link
                className="paginate_button previous disabled"
                to="/transactions"
                onClick={() =>
                  activePag.current > 0 && onClick(activePag.current - 1)
                }
              >
                Previous
              </Link>
              <span>
                {paggination.map((number, i) => (
                  <Link
                    key={i}
                    to="/transactions"
                    className={`paginate_button  ${
                      activePag.current === i ? "current" : ""
                    } `}
                    onClick={() => onClick(i)}
                    style={{ display: "inline-block" }}
                  >
                    {number}
                  </Link>
                ))}
              </span>
              <Link
                className="paginate_button next"
                to="/transactions"
                onClick={() =>
                  activePag.current + 1 < paggination.length &&
                  onClick(activePag.current + 1)
                }
              >
                Next
              </Link>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

function LoanView(props) {
  const [show, setShow] = useState(false);
  const [amountInvested, setAmountInvested] = useState();
  const [percentage, setPercentage] = useState();
  const [groupBalance, setGroupBalance] = useState();

  const [groupdetails, setGroupDetails] = useState("");
  const { id } = useParams();
  const handleClose = (success) => {
    if (success) {
      setShow(false);
      handleShowRequestSentDialog();
    } else setShow(false);
  };

  const [showRequestSentDialog, setShowRequestSentDialog] = useState(false);
  const handleCloseRequestSentDialog = (success) => {
    if (success) {
      setShowRequestSentDialog(false);
      props.setViewState(5);
    } else setShowRequestSentDialog(false);
  };
  const handleShowRequestSentDialog = () => setShowRequestSentDialog(true);

  const getGroupInfo = () => {
    axiosInstance.get(`/grp/groupInfo/${id}`).then((res) => {
      console.log(res.data.data);
      setGroupDetails(res.data.data);
    });
  };

  useEffect(() => {
    getGroupInfo();
  }, []);

  useEffect(() => {
    getAmountInvested().then((res) => {
      setAmountInvested(Number(res?.data?.amount));
    });
  }, []);

  useEffect(() => {
    getBorrowPercentage().then((res) => {
      setPercentage(Number(res?.data?.percentage));
    });
  }, []);

  useEffect(() => {
    getGroupCurrentBalance().then((res) => {
      setGroupBalance(res?.data?.amount);
    });
  }, []);

  async function getAmountInvested() {
    const res = await axiosInstance(
      `/grp_member/${id}/group-member/${
        JSON.parse(localStorage.getItem("userDetails")).userId
      }/balance`
    );
    return res?.data;
  }

  async function getBorrowPercentage() {
    const res = await axiosInstance(`/grp/${id}/percentage`);
    return res?.data;
  }

  async function getGroupCurrentBalance() {
    const res = await axiosInstance(`/grp/${id}/group_balance`);
    return res?.data;
  }

  const expiredata = moment(groupdetails?.group_expireDate);
  const todaydate = moment(new Date());

  console.log("expire Date", expiredata, "today Date", todaydate);

  const compare = expiredata.isBefore(todaydate);
  console.log("compare", compare);

  const handleShow = () => {
    if (compare) {
      swal({
        icon: "warning",
        title: "Group Expired",
      });
    } else {
      setShow(true);
    }
  };
  return (
    <div style={{ marginTop: "40px", marginBottom: "90px" }}>
      <LoanRequestDialog
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        handleShowRequestSentDialog={handleShowRequestSentDialog}
        amountInvested={amountInvested}
        percentage={percentage}
        groupBalance={groupBalance}
      />
      {/* <LoanRequestSentDialog
        showRequestSentDialog={showRequestSentDialog}
        handleCloseRequestSentDialog={handleCloseRequestSentDialog}
        handleShowRequestSentDialog={handleShowRequestSentDialog}
      /> */}
      <div
        className="card p-3 col-sm-12"
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <div className="col-sm-6">
          <p>Eligibility to Claim: {amountInvested > 0 ? "Yes" : "No"}</p>
          <p>Availability to Claim: {(groupBalance * percentage) / 100}</p>
        </div>
        <div className="col-sm-6">
          <button
            className="btn btn-secondary"
            onClick={handleShow}
            style={{ background: compare ? "grey" : "", border: "none" }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}

function LoanApprovedView() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loanrepaymentdata, setLoanRepaymentData] = useState([]);
  const [loandetailsdata, setLoanDetailsData] = useState({});
  const { id } = useParams();
  const data = [
    {
      date: "24 Nov 2022, 09:22 PM",
      status: "Paid",
      transactionId: "211455254155",
      amount: "4661",
      paymentMode: "UPI",
    },
    {
      date: "24 Nov 2022, 09:22 PM",
      status: "Paid",
      transactionId: "211455254155",
      amount: "4661",
      paymentMode: "UPI",
    },
    {
      date: "24 Nov 2022, 09:22 PM",
      status: "Due",
      transactionId: "-",
      amount: "-",
      paymentMode: "-",
    },
  ];

  const getLoanRepayments = async () => {
    const userid = JSON.parse(localStorage.getItem("userDetails")).userId;
    await axiosInstance
      .get(`/loan/getLoanDetails/${userid}/group/${id}`)
      .then((res) => {
        console.log(res.data.data);
        setLoanRepaymentData(res.data.data.transDetails);
        setLoanDetailsData(res.data.data.loanDetails);
      });
  };

  useEffect(() => {
    getLoanRepayments();
  }, []);

  // pagination code
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - loanrepaymentdata.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 3.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          style={{
            color: "white",
            borderRadius: "10px",
            background: page === 0 ? "#5b5288" : "#eb8153",
            marginLeft: "8px",
          }}
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background: page === 0 ? "#5b5288" : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background:
              page >= Math.ceil(count / rowsPerPage) - 1
                ? "#5b5288"
                : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          style={{
            color: "white",
            borderRadius: "10px",
            background:
              page >= Math.ceil(count / rowsPerPage) - 1
                ? "#5b5288"
                : "#eb8153",
            marginLeft: "8px",
          }}
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const [searchResult, setSearchResult] = useState({});
  const lowersearch = searchResult?.result?.toString().toLowerCase().trim();
  const usersearchall = loanrepaymentdata
    ?.filter(
      (sv) =>
        sv.status?.toLowerCase().includes(lowersearch) ||
        sv.transaction_id?.toLowerCase().includes(lowersearch) ||
        sv.amount?.toString().toLowerCase().includes(lowersearch)
    )
    .map((sitem) => {
      return sitem;
    });

  /*   console.log(usersearchall); */
  const onSearchHandler = (e) => {
    console.log(e.target.value, e.target.value.length);
    setSearchResult({
      result: e.target.value,
      length: e.target.value.length,
    });
  };

  return (
    <div style={{ marginTop: "40px" }}>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              {" "}
              <span style={{ fontSize: "22px" }}>Loan Details</span>{" "}
            </div>

            <div className="row card-body">
              <div className="col-sm-6">
                <p>Loan No: {loandetailsdata?.id}</p>
                <p>
                  Loan Amount:{" "}
                  {loandetailsdata?.loan_amount?.toLocaleString("fi-FI")}
                </p>
                <p>Recycle Method: {loandetailsdata?.repayment}</p>
                <p>
                  Loan Start Date:{" "}
                  {moment(loandetailsdata?.createdAt).format("DD/MM/YYYY")}
                </p>
                <p>
                  Next Loan Contribution Date:{" "}
                  {moment(loandetailsdata?.next_contribution).format(
                    "DD/MM/YYYY"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12">
          <div className="table-responsive table-hover fs-14">
            <div className="d-flex row align-items-baseline">
              <div className="col float-left mx-4 my-3">
                <span style={{ fontSize: "22px" }}>Transaction Details</span>
              </div>

              <div className="col float-right my-3">
                <input
                  className="form-control"
                  type="text"
                  name=""
                  onChange={onSearchHandler}
                  placeholder="Search Transaction"
                  id=""
                />
              </div>
            </div>
            <div id="example5_wrapper" className="dataTables_wrapper no-footer">
              <table
                className="table display mb-4 dataTablesCard short-one card-table text-black dataTable no-footer"
                id="transaction_table"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead style={{ textAlign: "center" }}>
                  <tr role="row">
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Transaction ID: activate to sort column ascending"
                      style={{ width: 124 }}
                    >
                      {" "}
                      Date
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date: activate to sort column ascending"
                      style={{ width: "54.6667px" }}
                    >
                      Transaction ID
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date: activate to sort column ascending"
                      style={{ width: "54.6667px" }}
                    >
                      Amount
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date: activate to sort column ascending"
                      style={{ width: "54.6667px" }}
                    >
                      Status
                    </th>

                    {/* <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date: activate to sort column ascending"
                      style={{ width: "54.6667px" }}
                    >
                      Actions
                    </th> */}
                  </tr>
                </thead>

                <tbody>
                  {loanrepaymentdata?.length === 0 && (
                    <tr
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <td
                        colSpan={5}
                        style={{ fontSize: "20px", color: "orange" }}
                      >
                        No Data Found
                      </td>
                    </tr>
                  )}

                  {searchResult.length > 0 ? (
                    <>
                      {usersearchall.length === 0 ? (
                        <>
                          <tr>
                            <td colSpan={5} style={{ textAlign: "center" }}>
                              <span
                                style={{ color: "orange", fontSize: "22px" }}
                              >
                                No Records Found
                              </span>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>{""}</>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {searchResult.length > 0 ? (
                    <>
                      {usersearchall?.map((uitem, i) => (
                        <React.Fragment>
                          {usersearchall?.length > 0 ? (
                            <>
                              <tr role="row" className="odd">
                                <td style={{ textAlign: "center" }}>
                                  <span>
                                    {moment(uitem?.transaction_date).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </td>

                                <td style={{ textAlign: "center" }}>
                                  <span style={{ textAlign: "center" }}>
                                    {uitem?.transaction_id}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span>
                                    {uitem?.amount.toLocaleString("fi-FI")}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span>{uitem?.status}</span>
                                </td>

                                {/* <td style={{ textAlign: "center" }}>
                        <div>
                          <button className="btn btn-primary btn-sm">
                            Details
                          </button>
                        </div>
                      </td> */}
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    <>
                      {(rowsPerPage > 0
                        ? loanrepaymentdata?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : loanrepaymentdata
                      )?.map((trans) => (
                        <tr role="row" className="odd">
                          <td style={{ textAlign: "center" }}>
                            <span>
                              {moment(trans?.transaction_date).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                          </td>

                          <td style={{ textAlign: "center" }}>
                            <span style={{ textAlign: "center" }}>
                              {trans?.transaction_id}
                            </span>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <span>{trans?.amount.toLocaleString("fi-FI")}</span>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <span>{trans?.status}</span>
                          </td>

                          {/* <td style={{ textAlign: "center" }}>
                        <div>
                          <button className="btn btn-primary btn-sm">
                            Details
                          </button>
                        </div>
                      </td> */}
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
                {emptyRows > 0 && (
                  <tr style={{ height: 41 * emptyRows }}>
                    <td colSpan={5} />
                  </tr>
                )}

                <tfoot>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={4}
                    count={
                      loanrepaymentdata?.length
                        ? loanrepaymentdata?.length
                        : "1"
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </tfoot>
              </table>
              {/*    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              Showing {activePag.current * sort + 1} to{" "}
              {data.length > (activePag.current + 1) * sort
                ? (activePag.current + 1) * sort
                : data.length}{" "}
              of {data.length} entries
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate"
            >
              <Link
                className="paginate_button previous disabled"
                to="/transactions"
                onClick={() =>
                  activePag.current > 0 && onClick(activePag.current - 1)
                }
              >
                Previous
              </Link>
              <span>
                {paggination.map((number, i) => (
                  <Link
                    key={i}
                    to="/transactions"
                    className={`paginate_button  ${
                      activePag.current === i ? "current" : ""
                    } `}
                    onClick={() => onClick(i)}
                    style={{ display: "inline-block" }}
                  >
                    {number}
                  </Link>
                ))}
              </span>
              <Link
                className="paginate_button next"
                to="/transactions"
                onClick={() =>
                  activePag.current + 1 < paggination.length &&
                  onClick(activePag.current + 1)
                }
              >
                Next
              </Link>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function LoanRequestDialog(props) {
  const [period, setPeriod] = useState("");
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [amount, setAmount] = useState();
  const [oneTimeDate, setOneTimeDate] = useState();
  const classes = useStyles();
  const handleChange = (e) => {
    setPeriod(e.target.value);
    console.log(e.target.value);
  };
  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
  };

  async function postLoanRequest() {
    try {
      if (amount === undefined || amount === "") {
        swal({
          title: "Failed",
          text: "Please enter an amount!",
          icon: "error",
          /*     button: "Aww yiss!", */
        });
        return;
      }
      if (period === undefined) {
        swal({
          title: "Failed",
          text: "Please select a repayment method",
          icon: "error",
          /*     button: "Aww yiss!", */
        });
        return;
      }
      if (period === "onetime" && oneTimeDate === undefined) {
        swal({
          title: "Failed",
          text: "Please select a repayment method",
          icon: "error",
          /*     button: "Aww yiss!", */
        });
        return;
      }
      const res = await axiosInstance.post(`/loan/applyLoan`, {
        userId: JSON.parse(localStorage.getItem("userDetails")).userId,
        groupId: id,
        loan_amount: amount,
        repayment: period,
      });
      swal({
        title: "Success",
        text: "Your request for loan is successfull. Admin will contact you shortly.",
        icon: "success",
        /*     button: "Aww yiss!", */
      }).then(function () {
        window.location = "/stockvelgroups";
        return res?.data;
      });
    } catch (error) {
      swal({
        title: "Failed",
        text: "There was an error processing the loan.",
        icon: "error",
        /*     button: "Aww yiss!", */
      });
    }
  }
  return (
    <Modal show={props.show} onHide={() => props.handleClose(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Loan Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="col-sm-12">
            <div
              className="card"
              style={{ backgroundColor: "var(--primary)", padding: "20px" }}
            >
              <p className="text-black" style={{ margin: "0" }}>
                Invested Amount: {props.amountInvested}
              </p>
              <p className="text-black" style={{ margin: "0" }}>
                Availability to Claim:{" "}
                {(props.groupBalance * props.percentage) / 100}
              </p>
            </div>
          </div>
          {/*      <div className="col-sm-12">
            <u>
              <p style={{ textAlign: "center" }}>Apply For Claim</p>
            </u>
          </div> */}

          <div
            className="col-sm-12"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <InputGroup className="mb-3">
              <InputGroup.Text
                id="basic-addon1"
                style={{
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                }}
              >
                Amount
              </InputGroup.Text>
              <FormControl
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Please enter an amount."
              />
            </InputGroup>
          </div>
          <div
            className="col-sm-12"
            style={
              {
                /*               display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center", */
              }
            }
          >
            <label htmlFor="">Select Repayment Method</label>
            <div className="form-group">
              <select
                className="form-control"
                aria-label="Default select example"
                onChange={handleChange}
                value={period}
              >
                <option selected>Please Select Repayment Method</option>
                <option value="onetime">One time</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>

            {/* 
            <Dropdown>
              <Dropdown.Toggle variant="var(--purple)" id="dropdown-basic">
                Repayment Method
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "100%" }}>
                <Dropdown.Item>
                  <p
                    style={{ textAlign: "center", margin: "0" }}
                    onClick={() => handleChange("onetime")}
                  >
                    One time
                  </p>
                </Dropdown.Item>
                <Dropdown.Item>
                  <p
                    style={{ textAlign: "center", margin: "0" }}
                    onClick={() => handleChange("weekly")}
                  >
                    Weekly
                  </p>
                </Dropdown.Item>
                <Dropdown.Item>
                  <p
                    style={{ textAlign: "center", margin: "0" }}
                    onClick={() => handleChange("monthly")}
                  >
                    Monthly
                  </p>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
          {period === "onetime" ? (
            <div
              className="col-sm-4"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p style={{ margin: "0" }}>Select a date</p>
            </div>
          ) : (
            ""
          )}
          {period === "onetime" ? (
            <div className="col-sm-8">
              <div
                className="row"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <div
                  className="col-sm-12"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    id="date"
                    type="date"
                    value={oneTimeDate}
                    onChange={(e) => setOneTimeDate(e.target.value)}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: moment(Date.now()).format("YYYY-MM-DD"),
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => {
            if (amount > (props.groupBalance * props.percentage) / 100) {
              swal("Amount entered cannot be higher than eligible claim.");
              console.log("failed");
              return;
            }
            postLoanRequest().then((res) => {
              if (res?.success) {
                toast.success("Loan request sent successfully.", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else {
                toast.error("Loan request failed.", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
            props.handleShowRequestSentDialog();
            props.handleClose(true);
          }}
          className="btn btn-primary"
        >
          Request Loan
        </button>
      </Modal.Footer>
    </Modal>
  );
}

// function LoanRequestSentDialog(props) {
//   return (
//     <div>
//       <Modal
//         show={props.showRequestSentDialog}
//         onHide={(e) => props.handleCloseRequestSentDialog(false)}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>
//             <p className="text-light" style={{ margin: "0" }}>
//               Loan request sent
//             </p>
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p style={{ margin: "0" }}>Please wait for approval</p>
//           <p style={{ margin: "0" }}>
//             To check the status of your loan
//             <span style={{ color: "var(--danger)" }}>click here</span>
//           </p>
//         </Modal.Body>
//         <Modal.Footer>
//           <button
//             className="btn btn-primary"
//             onClick={(e) => {
//               props.handleCloseRequestSentDialog(true);
//             }}
//           >
//             Close
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }
