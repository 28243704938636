import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { RangeDatePicker, DatePicker } from "@y0c/react-datepicker";
import swal from "sweetalert";
import axiosInstance from "../../../../../services/AxiosInstance";
import { makeStyles, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function CreateGroup() {
  const history = useHistory();
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [recurringDate, SetRecurringDate] = useState("");
  const [expriryDate, setExpiryDate] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupLimit, setGroupLimit] = useState("");
  const [monthlyInvestMent, setMonthlyInvestment] = useState("");
  const [loanlimitpercentage, setLoanLimitPercentage] = useState("");
  const [mandatoryRecurringPayment, setMandatoryRecurringPayment] =
    useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const [groupnameerror, setGroupNameError] = useState(false);
  const [grouplimiterror, setGroupLimitError] = useState(false);
  const [monthlyinvestmenterror, setMonthlyInvestmentError] = useState(false);
  const [recurringpaymenterror, setRecurringPaymentError] = useState(false);
  const [loanlimiterror, setLoanLimitError] = useState(false);
  const [groupexpireerror, setGroupExpireError] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleGroup = () => {
    /*     const flag = createGroupValidation();
    if (flag === false) return;
    setShow(false); */

    if (groupName === "" || groupName === null) {
      setGroupNameError(true);
    } else {
      setGroupNameError(false);
    }
    if (groupLimit === null || groupLimit === "") {
      setGroupLimitError(true);
    } else {
      setGroupLimitError(false);
    }
    if (monthlyInvestMent === null || monthlyInvestMent === "") {
      setMonthlyInvestmentError(true);
    } else {
      setMonthlyInvestmentError(false);
    }
    if (loanlimitpercentage === null || loanlimitpercentage === "") {
      setLoanLimitError(true);
    } else {
      setLoanLimitError(false);
    }

    if (recurringDate === null || recurringDate === "") {
      setRecurringPaymentError(true);
    } else {
      setRecurringPaymentError(false);
    }

    if (expriryDate === null || expriryDate === "") {
      setGroupExpireError(true);
    } else {
      setGroupExpireError(false);
    }

    if (
      groupLimit?.length > 0 &&
      groupnameerror === false &&
      grouplimiterror === false &&
      monthlyinvestmenterror === false &&
      loanlimiterror === false &&
      recurringpaymenterror === false &&
      groupexpireerror === false
    ) {
      postCreateGroup().then((res) => {
        console.log(res?.data?.success);
        console.log(res?.data?.data?.newGroup);
        postJoinGroup(res?.data?.data?.newGroup).then((res) =>
          console.log(res)
        );
        if (res?.data?.success === true) {
          swal({
            title: "Successfully Created!",
            // text: "You clicked the button!",
            icon: "success",
            button: "Okay!",
          }).then(() => {
            history.push(
              `/stockvelgroups/managegroup/${res?.data?.data?.newGroup?.id}`
            );
          });
        } else {
          swal({
            title: "Failed",
            text: "Try again with different group name",
            icon: "error",
            button: "Okay!",
          });
        }
      });
    }
  };

  function createGroupValidation() {
    if (groupName === "" || groupName === null) {
      swal({
        title: "Failed",
        text: "Group name field was left empty",
        icon: "error",
        button: "Okay!",
      });
      return false;
    }
    if (groupLimit === null || groupLimit === "") {
      swal({
        title: "Failed",
        text: "Group limit was not set",
        icon: "error",
        button: "Okay!",
      });
      return false;
    }
    if (monthlyInvestMent === null || monthlyInvestMent === "") {
      swal({
        title: "Failed",
        text: "monthly investment amount was not set",
        icon: "error",
        button: "Okay!",
      });
      return false;
    }
    if (loanlimitpercentage === null || loanlimitpercentage === "") {
      swal({
        title: "Failed",
        text: "loan limit was not set",
        icon: "error",
        button: "Okay!",
      });
      return false;
    }
    return true;
  }
  async function postCreateGroup() {
    try {
      const res = await axiosInstance
        .post(`/grp/createGroup`, {
          group_name: groupName,
          group_limit: groupLimit,
          group_expire_date: expriryDate,
          monthly_investment_amount: monthlyInvestMent,
          userId: JSON.parse(localStorage.getItem("userDetails")).userId,
          role: "created",
          recurring_payment_date: recurringDate,
          percentage: loanlimitpercentage,
        })
        .catch((e) => {
          console.log("hm");
        });
      return res;
    } catch (e) {
      swal({
        title: "Failed",
        text: "Try again with different group name",
        icon: "error",
        button: "Okay!",
      });
    }
  }

  async function postJoinGroup(data) {
    try {
      const res = await axiosInstance.post(`/grp_member/createGroupMembers`, {
        groupId: data.id,
        userId: data.user_id,
        group_member_role: "Admin",
        member_involved_by: data.user_id,
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create a group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Create group module */}
          <div className="row ">
            <div className="col">
              {/* <div className="card">
									<div className="card-body"> */}
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="">Group Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name your group"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                      />
                      <div>
                        {groupnameerror && (
                          <div>
                            <span
                              style={{
                                color: "orange",
                                fontSize: "13px",
                              }}
                            >
                              Please Enter Group Name
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label className="">Group Limit:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Group Limit"
                        value={groupLimit}
                        onChange={(e) => {
                          if (isNaN(e.target.value)) {
                            return;
                          } else {
                            setGroupLimit(e.target.value);
                          }
                        }}
                      />
                      {grouplimiterror && (
                        <div>
                          <span
                            style={{
                              color: "orange",
                              fontSize: "13px",
                            }}
                          >
                            Please Enter Group Limit
                          </span>
                        </div>
                      )}

                      {/* <select
															defaultValue={"option"}
															className="form-control  mb-0"
															id="sel1"
														>
															<option value="option" disabled> --</option>
															<option>15</option>
															<option>25</option>
															<option>30</option>
															<option>50</option>
															<option>100</option>
														</select> */}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="">Monthly Investment</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter amount"
                        value={monthlyInvestMent}
                        onChange={(e) => {
                          if (isNaN(e.target.value)) {
                            return;
                          } else {
                            setMonthlyInvestment(e.target.value);
                          }
                        }}
                      />
                      <div>
                        {monthlyinvestmenterror && (
                          <div>
                            <span
                              style={{
                                color: "orange",
                                fontSize: "13px",
                              }}
                            >
                              Please Enter Monthly Investment Amount
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group col-md-6">
                      <label className="">Loan Limit %</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Loan Limit Percentage"
                        value={loanlimitpercentage}
                        onChange={(e) => {
                          if (isNaN(e.target.value)) {
                            return;
                          } else {
                            setLoanLimitPercentage(e.target.value);
                          }
                        }}
                      />
                      <div>
                        {loanlimiterror && (
                          <div>
                            <span
                              style={{
                                color: "orange",
                                fontSize: "13px",
                              }}
                            >
                              Please Enter Loan Limit Percentage
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="">Recurring Payment Date</label>
                      {/* <DatePicker/> */}
                      <form className={classes.container} noValidate>
                        <TextField
                          id="date"
                          type="date"
                          inputProps={{
                            min: moment(Date.now()).format("YYYY-MM-DD"),
                          }}
                          value={recurringDate}
                          onChange={(e) => {
                            SetRecurringDate(e.target.value);
                          }}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {recurringpaymenterror && (
                          <div>
                            <span
                              style={{
                                color: "orange",
                                fontSize: "13px",
                              }}
                            >
                              Please Select Recurring Payment Date
                            </span>
                          </div>
                        )}
                      </form>
                    </div>
                    <div className="form-group col-md-6">
                      <label className="">Group expire date</label>
                      {/* <DatePicker /> */}
                      <form className={classes.container} noValidate>
                        <TextField
                          id="date"
                          type="date"
                          inputProps={{
                            min: moment(Date.now()).format("YYYY-MM-DD"),
                          }}
                          value={expriryDate}
                          onChangeCapture={(e) => setExpiryDate(e.target.value)}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {groupexpireerror && (
                          <div>
                            <span
                              style={{
                                color: "orange",
                                fontSize: "13px",
                              }}
                            >
                              Please Select Group Expire Date
                            </span>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                  {/* <div className="form-row">
                      const handleGroup = () => {
    const flag = createGroupValidation();
    if (flag === false) return;
    setShow(false);
    postCreateGroup().then((res) => {
      swal({
        title: "Successfully Created!",
        // text: "You clicked the button!",
        icon: "success",
        button: "Okay!",
      });
    });
  };

  function createGroupValidation() {
    if (groupName === "") {
      swal({
        title: "Failed",
        text: "Group name field was left empty",
        icon: "error",
        button: "Okay!",
      });
      return false;
    }
    if (groupLimit === null) {
      swal({
        title: "Failed",
        text: "Group limit was not set",
        icon: "error",
        button: "Okay!",
      });
      return false;
    }
    if (monthlyInvestMent === null) {
      swal({
        title: "Failed",
        text: "monthly investment amount was not set",
        icon: "error",
        button: "Okay!",
      });
      return false;
    }
    return true;
  }
  async function postCreateGroup() {
    const res = await axiosInstance.post(
      `/grp/createGroup`,
      {
        group_name: groupName,
        group_limit: groupLimit,
        group_expire_date: expriryDate,
        monthly_investment_amount: monthlyInvestMent,
        user_id: JSON.parse(localStorage.getItem("userDetails")).userId,
        role: "created",
        recurring_payment_date: recurringDate,
      }
      // {
      //   headers: {
      //     "x-token": JSON.parse(localStorage.getItem("userDetails"))
      //       .idToken,
      //   },
      // }
    );
  }
      --
                          </option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                          <option>13</option>
                          <option>14</option>
                          <option>15</option>
                          <option>16</option>
                          <option>17</option>
                          <option>18</option>
                          <option>19</option>
                          <option>20</option>
                          <option>21</option>
                          <option>22</option>
                          <option>23</option>
                          <option>24</option>
                          <option>25</option>
                          <option>26</option>
                          <option>27</option>
                          <option>28</option>
                          <option>29</option>
                          <option>30</option>
                          <option>31</option>
                        </select>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="text-white">Month</label>
                        <select
                          defaultValue={"option"}
                          className="form-control  mb-0"
                          id="sel1"
                        >
                          <option value="option" disabled>
                            {" "}
                            --
                          </option>
                          <option>Janauray</option>
                          <option>Feburary</option>
                          <option>March</option>
                          <option>April</option>
                          <option>May</option>
                          <option>June</option>
                          <option>July</option>
                          <option>August</option>
                          <option>September</option>
                          <option>Octobar</option>
                          <option>November</option>
                          <option>December</option>
                        </select>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="text-white">Year</label>
                        <select
                          defaultValue={"option"}
                          className="form-control  mb-0"
                          id="sel1"
                        >
                          <option value="option" disabled>
                            {" "}
                            --
                          </option>
                          <option>2022</option>
                          <option>2023</option>
                          <option>2024</option>
                          <option>2025</option>
                          <option>2026</option>
                          <option>2027</option>
                        </select>
                      </div>
                    </div> */}
                  {/*          <div className="form-row d-flex justify-content-between mt-4 mb-2">
                    <div className="form-group">
                      <div className="custom-control custom-checkbox ml-1 ">
                        <input
                          type="checkbox"
                          className="custom-control-input mr-2"
                          id="basic_checkbox_1"
                          value={mandatoryRecurringPayment}
                          onChange={(e) =>
                            setMandatoryRecurringPayment(e.target.value)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="basic_checkbox_1"
                        >
                          Mandatory Recurring Payment
                        </label>
                      </div>
                    </div>
                  </div> */}
                </form>
              </div>
              {/* </div>
								</div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="btn btn-primary" onClick={handleGroup}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div className="col d-flex justify-content-end align-items-end my-4">
          <Button className="btn btn-success " onClick={handleShow}>
            Create Group
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CreateGroup;
