export const ProfileData = [
    {
      id:1,
      first_name:"John",
      last_name:"Wingh",
      icon:'http://localhost:3000/static/media/Untitled-1.8116907a.jpg',
      total_group:20,
      total_joined_groups:5,
      phone_number:21319842,
      email:"john@gmail.com",
      dob:"12/02/1887",  
      acc_no:12324241214,
      ifsc_code:"ADJ9782",
      bank_name:"Bank Of India" ,
      role:"Admin",
      address:"Bangalore, near ashram circle",
      state:"Karnataka",
      city:"basavan gudi"   


    }, 
  
  ];

  export const joinrequestdata =[
{
  id:1,
  group_name:"Stockvel Investment Group",
  total_members:23,
  icon:'http://localhost:3000/static/media/Untitled-1.8116907a.jpg',
}
  ];



 