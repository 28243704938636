import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: `https://stockvel.medlent.in`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.auth.idToken;
    config.params = config.params || {};
    // config.params["auth"] = token;
    config.headers["x-token"] = token;
    return config;
  },
  function (error) {
    console.log(error);
    throw error;
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    console.log(error);
    throw error;
  }
);

// axiosInstance.interceptors.request.use((config) => {
//   const state = store.getState();
//   const token = state.auth.auth.idToken;
//   config.params = config.params || {};
//   config.params["auth"] = token;
//   config.headers["x-token"] = token;
//   return config;
// });

export default axiosInstance;
