import { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";
import axiosInstance from "../../../../../../services/AxiosInstance";

export default function AddTransactionModal({
  setCurrentBalance,
  getGroupCurrentBalance,
  compare,
}) {
  const param = useParams();
  const [groupMembers, setGroupMembers] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    if (compare) {
      swal({
        icon: "warning",
        title: "Group Expired",
      });
    } else {
      setShow(true);
    }
  };
  const [selectedName, setSelectedName] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [amount, setAmount] = useState("");
  let errorsObj = { member: "", payment: "", amount: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [loanid, setLoanID] = useState("");

  const validateForm = () => {
    let error = false;
    const errorObj = { ...errorsObj };

    if (amount === "") {
      errorObj.amount = "Please enter your investing amount";
      error = true;
    }
    if (paymentType === null) {
      errorObj.payment = "Please select your payment method";
      error = true;
    }
    if (selectedName === null) {
      errorObj.member = "Please select member";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    return error ? false : true;
  };

  useEffect(() => {
    getGroupMembers().then((res) => {
      console.log(res?.data?.data);
      setGroupMembers(res?.data?.data);
    });
  }, []);
  const linksendHandle = () => {
    if (validateForm()) {
      postTransaction().then((res) => {
        if (res?.data?.success === true) {
          toast.success("Transaction added successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getGroupCurrentBalance().then((res) => {
            setCurrentBalance(res?.data?.amount);
          });
        }
      });
      setShow(false);
    }
  };
  const { id } = useParams();
  const GetLoanIDData = () => {
    axiosInstance.get(`/loan/getLoanTakenList/${id}`).then((res) => {
      console.log(res.data.data.loanData);

      console.log("userid", selectedId);
      const loan_id = res?.data.data.loanData
        .filter((uid) => uid.user.id === selectedId)
        .filter((re) => re.loan_status === "Active");

      const loanidno = loan_id[0]?.id;

      /*      console.log("loan ID", loan_id[0].id); */
      setLoanID(loanidno);
    });
  };

  useEffect(() => {
    GetLoanIDData();
  }, [selectedId]);

  async function postTransaction() {
    try {
      if (paymentType === "REPAYMENT") {
        const res = await axiosInstance.post(`loan/repayment/${loanid}`, {
          userId: selectedId,
          groupId: param?.id,
          repaymentAmount: amount,
          type: paymentType.toLowerCase(),
        });

        console.log("repayment if", res);
        window.location.reload();
        return res;
      } else {
        const res = await axiosInstance.post(`/grp_trans/group_transaction`, {
          groupId: param?.id,
          groupMemberId: selectedId,
          amount: amount,
          type: paymentType.toLowerCase(),
          userId: JSON.parse(localStorage.getItem("userDetails")).userId,
        });
        console.log("repayment else", res);
        window.location.reload();
        return res;
      }
    } catch (e) {
      console.log(e.response.data.errorMessage);
      swal({
        icon: "warning",
        title: e.response.data.errorMessage,
      }).then(function () {
        window.location.reload();
      });
      toast.error("Transaction was not added successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function getGroupMembers() {
    const res = await axiosInstance.get(
      `/grp_member/getAllGroupMember/${param.id}`
    );
    return res?.data;
  }
  return (
    <div>
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <button
          style={{
            background: compare ? "grey" : "#eb8153",
            border: "none",
            width: "100%",
          }}
          className="btn btn-primary "
          onClick={handleShow}
        >
          Add Transaction
        </button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="">Add transaction</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row d-flex align-items-center flex-column justify-content-around">
              <div className="row ">
                <div className="col">
                  {/* <div className="card">
									<div className="card-body"> */}
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="">Select user</label>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="primary"
                              id="dropdown-basic"
                            >
                              {selectedName === null
                                ? "Select a member"
                                : selectedName}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {groupMembers.map((member) => {
                                return (
                                  <Dropdown.Item
                                    value={member?.user_id}
                                    onClick={(e) => {
                                      setSelectedId(member?.user_id);
                                      setSelectedName(member?.user?.first_name);
                                    }}
                                  >
                                    <p
                                      style={{
                                        margin: "0",
                                        fontSize: ".7rem",
                                        fontWeight: "400",
                                      }}
                                    >
                                      ID:{" "}
                                      <span style={{ fontWeight: "600" }}>
                                        {member?.user_id}
                                      </span>
                                    </p>
                                    <p
                                      style={{
                                        margin: "0",
                                        fontSize: ".7rem",
                                        fontWeight: "400",
                                      }}
                                    >
                                      Name:{" "}
                                      <span style={{ fontWeight: "600" }}>
                                        {member?.user?.first_name +
                                          " " +
                                          member?.user?.last_name}
                                      </span>
                                    </p>
                                    <p
                                      style={{
                                        margin: "0",
                                        fontSize: ".7rem",
                                        fontWeight: "400",
                                      }}
                                    >
                                      Email:{" "}
                                      <span style={{ fontWeight: "600" }}>
                                        {member?.user?.email}
                                      </span>
                                    </p>
                                    <Dropdown.Divider />
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                          {errors.member && (
                            <div className="text-orange mt-2">
                              {errors.member}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label className="">Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                          {errors.amount && (
                            <div className="text-orange mt-2">
                              {errors.amount}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="">Choose payment type</label>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="primary"
                              id="dropdown-basic"
                            >
                              {paymentType === null ? "Choose" : paymentType}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                value={"DEBIT"}
                                onClick={(e) => {
                                  setPaymentType("DEBIT");
                                }}
                              >
                                Debit
                              </Dropdown.Item>
                              <Dropdown.Item
                                value={"CREDIT"}
                                onClick={(e) => {
                                  setPaymentType("CREDIT");
                                }}
                              >
                                Credit
                              </Dropdown.Item>
                              <Dropdown.Item
                                value={"REPAYMENT"}
                                onClick={(e) => {
                                  setPaymentType("REPAYMENT");
                                }}
                              >
                                Loan Repayment
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          {errors.payment && (
                            <div className="text-orange mt-2">
                              {errors.payment}
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              CANCEL
            </Button>
            <Button variant="primary" onClick={linksendHandle}>
              SUBMIT
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
  // <button className={"btn btn-primary"}>Add Transaction</button>
}
