import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

function JoinRequestsModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };
  return (
    <div className="">
      <button
        type="button"
        onClick={handleShow}
        class="btn btn-secondary position-relative px-4"
      >
        Join Requests
        <span class="position-absolute top-0 mx-2 start-100 translate-middle badge rounded-pill bg-success">
          3
        </span>
      </button>

      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="">Join Requests</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row   ">
              <div className="col-md-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Member</th>
                      <th>Group Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Indira</td>
                      <td>Stokvel Investment45</td>
                      <td className="text-success mx-3">Accept</td>
                      <td className="text-danger mx-3">Decline</td>
                    </tr>
                    <tr>
                      <td>Vivek</td>
                      <td>Stokvel Property Group</td>
                      <td className="text-success mx-3">Accept</td>
                      <td className="text-danger mx-3">Reject</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          {/*   <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleShow}>
              More Detail
            </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
}

export default JoinRequestsModal;
