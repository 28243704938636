import React, { useState, useEffect } from "react";

import { Card, Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import axiosInstance from "../../../../../../services/AxiosInstance";

import { ProfileData } from "../../jsonData/profiledata";

function Residency() {
  const userid = JSON.parse(localStorage.getItem("userDetails")).userId;
  const [editmodalshow, setEditModalShow] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [userDetailsedit, setUserDetailsedit] = useState();

  const edithandleClose = () => setEditModalShow(false);
  const edithandleShow = () => setEditModalShow(true);

  const getSingleUser = () => {
    axiosInstance.get(`/user/userView/${userid}`).then((res) => {
      console.log(res.data.data.user);
      setUserDetails(res?.data?.data.user);
      setUserDetailsedit(res?.data?.data.user);
    });
  };

  useEffect(() => {
    getSingleUser();
  }, [editmodalshow]);

  const updateResidencedata = () => {
    axiosInstance.put(`/user/update/${userid}`, userDetailsedit).then((res) => {
      console.log(res);
      setEditModalShow(false);
      swal({
        title: "Address Updated!",
        text: "Residency Address is Updated!",
        icon: "success",
        timer: "2000",
      }).then(() => {});
    });
  };

  const editHandleChange = (e) => {
    setUserDetailsedit({ ...userDetailsedit, [e.target.name]: e.target.value });
  };

  console.log(userDetailsedit);

  return (
    <div>
      <>
        <Modal show={editmodalshow} onHide={edithandleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <span>Edit Residence Address</span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="form-group">
                <label htmlFor="">Address</label>

                <input
                  type="text"
                  value={userDetailsedit?.address}
                  name="address"
                  className="form-control"
                  id=""
                  onChange={editHandleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">City</label>

                <input
                  type="text"
                  name="city"
                  value={userDetailsedit?.city}
                  className="form-control"
                  id=""
                  onChange={editHandleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Country</label>

                <input
                  type="text"
                  name="country"
                  value={userDetailsedit?.country}
                  className="form-control"
                  id=""
                  onChange={editHandleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Postal Code</label>

                <input
                  type="text"
                  name="postal_code"
                  value={userDetailsedit?.postal_code}
                  className="form-control"
                  id=""
                  onChange={editHandleChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={edithandleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={updateResidencedata}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </>

      <Card>
        <span style={{ fontSize: "20px" }}>Residency Information</span>
        <Card.Body>
          <Card.Text>
            <div className="row">
              <div className="col-md-12">
                {ProfileData.map((data, i) => {
                  return (
                    <table className="table table-hover">
                      <tr>
                        <td>Address</td>
                        <td>{userDetails?.address}</td>
                      </tr>
                      <tr>
                        <td>City</td>
                        <td>{userDetails?.city}</td>
                      </tr>
                      <tr>
                        <td>Country</td>
                        <td>{userDetails?.country}</td>
                      </tr>
                      <tr>
                        <td>Postal Code</td>
                        <td>{userDetails?.postal_code}</td>
                      </tr>
                    </table>
                  );
                })}
              </div>
            </div>
            <div className="row ">
              <div>
                <button
                  className="btn btn-primary mt-5"
                  onClick={edithandleShow}
                >
                  Edit
                </button>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Residency;
