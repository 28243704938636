import swal from "@sweetalert/with-react";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axiosInstance from "../../../../../../services/AxiosInstance";

function LoanRequestsLink(props) {
  const [show, setShow] = useState(false);
  const [loanRequestCount, setLoanRequestCount] = useState();
  const [data, setData] = useState([]);

  const { id } = useParams();
  const handleClose = () => {
    setShow(false);
    // window.location.reload();
  };

  const handleShow = () => {
    if (props.compare) {
      swal({
        icon: "warning",
        title: "Group Expired",
      });
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    getLoanRequestData().then((res) => {
      setData(res.data?.data);
      console.log("loansubmitted", res.data?.data);
    });
  }, []);

  console.log("newdata", data);

  async function getLoanRequestData() {
    const res = await axiosInstance(`/loan/submitted/group/${id}`);

    return res?.data;
  }

  async function postLoanAction(loanId, action) {
    /*     const data = {
      groupId: `${id}`,
      member_id: loanId,
    };

    console.log(data); */

    const res = await axiosInstance
      .put(
        `/loan/${action}Loan/${
          JSON.parse(localStorage.getItem("userDetails")).userId
        }/${action}`,
        {
          groupId: `${id}`,
          memberId: loanId,
        }
      )
      .then((res) => {
        console.log(res.data?.data?.message);
        if (res.data?.data?.message === "Member Loan Approved!") {
          swal({
            icon: "success",
            title: res.data?.data?.message,
          }).then((dat) => {
            handleClose();
            getLoanRequestData();
            /*    window.location.reload(); */
          });
        }
      });

    if (res?.data?.data?.success) {
      console.log("pass");
    }
    getLoanRequestData().then((res) => {
      console.log("loansubmitted", res);
    });
  }
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <button
        type="button"
        onClick={handleShow}
        className="btn btn-warning position-relative"
        style={{
          background: props.compare ? "grey" : "#20c997",
          border: "none",
          color: "white",
          width: "100%",
        }}
      >
        Loan Requests
        <span className="position-absolute top-0 mx-3 start-100 translate-middle badge rounded-pill bg-info">
          {data.length}
        </span>
      </button>

      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="">Loan Requests</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row   ">
              <div className="col-md-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Member</th>
                      <th>Loan Amount</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {data?.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan={3}>
                          {" "}
                          <div style={{ marginTop: "10px" }}>
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                color: "orange",
                              }}
                            >
                              No Loan Applications at The Moment.
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {data.map((row) => {
                        return (
                          <tr>
                            <td>{row?.user?.first_name}</td>
                            <td>{row?.loan_amount}</td>
                            <td
                              onClick={(e) => {
                                postLoanAction(row?.user_id, "approve");
                              }}
                              className="text-success mx-3"
                              style={{ cursor: "pointer" }}
                            >
                              Approve
                            </td>
                            <td
                              onClick={(e) => {
                                postLoanAction(row?.user_id, "reject");
                              }}
                              className="text-danger mx-3"
                              style={{ cursor: "pointer" }}
                            >
                              Reject
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </Modal.Body>
          {/*   <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleShow}>
              More Detail
            </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
}

export default LoanRequestsLink;
