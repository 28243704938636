import {
  CREATE_INVESTMENT_DATA,
  GET_INVESTMENT_TABDATA,
  SIDE_MENU_TOGGLE,
} from "../actions/AdminsideTypes";

const intitialState = {
  investmentdata: [],
  toggle: "",
};

export const investmentReducer = (state = intitialState, action) => {
  switch (action.type) {
    case GET_INVESTMENT_TABDATA:
      return {
        ...state,
        investmentdata: action.payload,
      };
    case SIDE_MENU_TOGGLE:
      return {
        ...state,
        toggle: action.payload,
      };

    case CREATE_INVESTMENT_DATA:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
