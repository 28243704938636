import axiosInstance from "../../services/AxiosInstance";
import {
  CREATE_INVESTMENT_DATA,
  GET_INVESTMENT_TABDATA,
  SIDE_MENU_TOGGLE,
} from "./AdminsideTypes";

/* const addinvestment = () => ({
  type: CREATE_INVESTMENT_DATA,
});

export const addInvestmentData = (user) => {
  return function (dispatch) {
    axiosInstance.post(`/user/create_user`, user).then((response) => {
      dispatch(userAdded());
    });
  };
}; */

const getInvestment = (data) => ({
  type: GET_INVESTMENT_TABDATA,
  payload: data,
});

export const GetInvestmentData = (id) => {
  return function (dispatch) {
    axiosInstance
      .get(`/investment/${id}`)
      .then((res) => {
        /*   console.log("getusers", res); */

        dispatch(getInvestment(res.data.data.data));
      })
      .catch((error) => console.log(error));
  };
};

const sidemenutoggle = (data) => ({
  type: SIDE_MENU_TOGGLE,
  payload: data,
});

export const ChangeSideMenuToggle = (data) => {
  return function (dispatch) {
    /*     axiosInstance
          .get(`/investment/${id}`)
          .then((res) => {
            dispatch(getInvestment(res.data.data.data));
          })
          .catch((error) => console.log(error));
      }; */

    dispatch(sidemenutoggle(data));
  };
};
